<template>
  <DrDrawer
    :title="$t('permissions.invite_members.invite_user')"
    size="large"
    no-footer
    :shown="shown"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @close="checkAndHandleClose"
    @cancel="handleCancel"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="formSchema"
      :entity="inviteFormEntity"
      :errors="formErrors"
      @update="handleUpdateForm"
      @submit="handleSubmit"
    >
      <template #emails="{ editProps, error, dataTestId }">
        <ElFormItem
          :prop="editProps.schema.prop"
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
          :class="$style.customFormItem"
        >
          <template #label>
            <div :class="$style.asterisk">{{ editProps.schema.label }}</div>
            <div>
              <AddFromGroupLink
                :room-id="roomId"
                @add="handleAddMemberFromGroup"
              />
            </div>
          </template>

          <ElSelect
            v-model="inviteFormEntity.emails"
            multiple
            filterable
            remote
            :reserve-keyword="false"
            :placeholder="editProps.schema.placeholder"
            :no-match-text="$t('form.email_is_invalid')"
            :no-data-text="$t('form.email_is_invalid')"
            :remote-method="remoteMethod"
            @change="editProps.veeField.onChange"
            @blur="editProps.veeField.onBlur"
          >
            <ElOption
              v-for="item in emailsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </ElSelect>
        </ElFormItem>
      </template>
    </DrDynamicFormClassic>

    <div :class="$style.footer">
      <ElButton @click="handleCancel">{{ $t("shared.cancel") }}</ElButton>
      <ElButton type="primary" :disabled="disallowSubmit" @click="handleSubmit">
        {{ $t("permissions.invite_members.invite_user") }}
      </ElButton>
    </div>

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { cloneDeep, set } from "lodash-es";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateArrayString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { ROOM_DATA } from "@setups/index";
import { $notifyInfo, isStringContains } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { parseEmailLine } from "@drVue/components/client-dashboard/users/AddUserModal/emails-parser";
import DrStore from "@drVue/store";
import AddFromGroupLink from "./MembersAddFromGroupLink.vue";

import type { AddMembersPayload } from "./types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

export interface MembersInvitePanelOpenParams {
  groupId: AddMembersPayload["pgroupId"] | null;
}

type OptionItem = {
  label: string;
  value: string;
};

interface Emits {
  (event: "closed"): void;
  (event: "invited"): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const roomId = ROOM_DATA.id;
const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);
const shown = ref(false);

const orgUsers = computed<OptionItem[]>(() =>
  DrStore.state.clientDashboard.orgUsers.items.map(
    ({ name, email, pending }: OrgUser) => ({
      label: !pending && name ? `${name} <${email}>` : email,
      value: email,
    }),
  ),
);

const emailsOptions = ref<OptionItem[]>([]);
const remoteMethod = (query: string) => {
  let orgUsersEmails = orgUsers.value.slice();

  if (query) {
    orgUsersEmails = orgUsersEmails.filter((item) =>
      isStringContains(query, item.value),
    );
    const maybeQueryIsEmail = parseEmailLine(query);
    if (maybeQueryIsEmail && maybeQueryIsEmail[0]) {
      orgUsersEmails.push({ label: "", value: maybeQueryIsEmail[0] });
    }
  }

  emailsOptions.value = orgUsersEmails;
};

type InviteForm = {
  emails: AddMembersPayload["data"]["members"][number]["email"][];
  message: AddMembersPayload["data"]["message"];
  groupId: AddMembersPayload["pgroupId"] | null;
};

const inviteEmptyEntity: InviteForm = {
  emails: [],
  message: "",
  groupId: null,
};

const inviteFormEntity = reactive<InviteForm>(cloneDeep(inviteEmptyEntity));

type SelectExtra = {
  select_options?: ReadonlyArray<{
    label: string;
    value: AddMembersPayload["pgroupId"];
  }>;
  autofocus?: boolean | number;
};

const groupsSelectList = computed(() => {
  return DrStore.state.room.groups.pgroupsList.map((group) => ({
    label: group.name,
    value: group.id,
  }));
});

const formSchema = computed<FormSchema<SelectExtra>>(() => {
  return [
    {
      type: FieldSchemaType.Custom,
      prop: "emails",
      label: t("shared.emails"),
      placeholder: t("form.emails_placeholder"),
      rules: validateArrayString().min(1, t("form.emails_is_required")),
      required: true,
      extra: {
        autofocus: 300,
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "groupId",
      label: t("permissions.group"),
      placeholder: t("permissions.group_placeholder"),
      required: true,
      extra: {
        select_options: groupsSelectList.value,
      },
    },
    {
      type: FieldSchemaType.Text,
      prop: "message",
      label: t("shared.message"),
      placeholder: t(
        "permissions.invite_members.invite_message_field_placeholder",
      ),
    },
  ];
});

const {
  formErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<AddMembersPayload["data"]>();

const handleOpen = (params: MembersInvitePanelOpenParams) => {
  inviteFormEntity.groupId = params.groupId;
  shown.value = true;
};

const handleClose = () => {
  shown.value = false;
  emit("closed");
};

// reset form on "discard"
const handleCancel = () => {
  handleClose();
  resetErrors();
  Object.assign(inviteFormEntity, cloneDeep(inviteEmptyEntity));
  formRef.value?.reset();
};

const checkAndHandleClose = () => {
  if (!inviteFormEntity.emails.length && !inviteFormEntity.message.length) {
    handleClose();
  } else {
    ElMessageBox.confirm(t("form.discard_confirm"), t("form.unsaved_changes"), {
      confirmButtonText: t("shared.discard"),
      cancelButtonText: t("shared.cancel"),
      customClass: "el-message-box--warning",
      showClose: false,
    }).then(handleCancel);
  }
};

const handleUpdateForm = ({ field, value }: { field: string; value: any }) => {
  resetError(field);
  set(inviteFormEntity, field, value);
};

const handleAddMemberFromGroup = (emails: string[]) => {
  if (!emails.length) {
    $notifyInfo(t("permissions.invite_members.no_emails_added_from_group"));
    return;
  }

  resetError("emails");

  if (emails.some((e) => inviteFormEntity.emails.includes(e))) {
    $notifyInfo(t("permissions.invite_members.duplicate_emails_filtered"));
  }

  inviteFormEntity.emails = Array.from(
    new Set([...inviteFormEntity.emails, ...emails]),
  );
};

const disallowSubmit = computed(() => !inviteFormEntity.emails.length);

const handleSubmit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();

  if (isValid) {
    const { groupId, message, emails } = inviteFormEntity;

    const inviteData: AddMembersPayload = {
      pgroupId: groupId!,
      data: {
        members: emails.map((email) => ({ email })),
        message,
      },
    };

    await hookFormSubmitPromise(
      DrStore.dispatch("room/members/addMembers", inviteData).then(
        () => {
          Object.assign(inviteFormEntity, cloneDeep(inviteEmptyEntity));
          handleClose();
          emit("invited");
        },
        () => {},
      ),
    );
  }
};

defineExpose({
  open: handleOpen,
  close: handleClose,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.customFormItem :global(.el-form-item__label) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
}

.asterisk:after {
  color: colors.$sc-600;
  content: "*";
  margin-left: 4px;
}

.footer {
  margin-top: spacing.$l;
  padding: spacing.$l 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: spacing.$m;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
