import { ElMessageBox } from "element-plus";
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { trim } from "lodash";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { ROOM_DATA } from "@app/setups";
import { documentViewUrl } from "@app/setups/room-urls";
import {
  $notifyDanger,
  $notifySuccess,
  createDictionary,
} from "@app/vue/common";
import {
  type FolderMenuParams,
  getDocumentsCountText,
  getDocumentsSelectedItemsText,
} from "@app/vue/components/room/documents/utils";
import { t } from "@app/vue/i18n";
import {
  type ArchiveItemsPayload,
  type BookmarkItemsPayload,
  DOC_ITEM_TYPES,
  type Document,
  DocumentsApiService,
  type Folder,
  type GetTreeResponse,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";

import type { TreeItem } from "@app/vue/shared/ui/dr-tree/types";

export type AttachmentsTreeNode = TreeItem<{
  label: string;
  canParent: boolean;
  type: DOC_ITEM_TYPES;
  mimetype?: string;
}>

const api = new DocumentsApiService();

const STOPWORDS = createDictionary({
  a: true,
  an: true,
  and: true,
  the: true,
  "&": true,
  "": true,
});

function pathScore(categoryPath: FolderPath, folderPath: FolderPath) {
  return window.path_score(categoryPath, folderPath);
}

function convertToWordArray(str: string): WordArray {
  return str
    .toLowerCase()
    .split(" ")
    .reduce<WordArray>((acc, word) => {
      const trimmedWord = trim(word);
      if (!STOPWORDS[trimmedWord]) acc.push(trimmedWord);

      return acc;
    }, []);
}

type WordArray = string[];
type FolderPath = WordArray[];

export const filterTree = <Ext>(
  current: TreeItem,
  predicate: (node: TreeItem<Ext>) => boolean,
): TreeItem => {
  if (!current || !current.children || !Array.isArray(current.children))
    return current;

  return {
    ...current,
    children: (current.children as TreeItem<Ext>[])
      .filter(predicate)
      .map((item) => filterTree(item, predicate)),
  };
};

export const setTreePosition = (treeList: (Document | Folder)[]) => {
  treeList.forEach((item) => {
    item.treeIndexList = item.tree_index
      .split(".")
      .map((index) => Number.parseInt(index));
  });

  treeList.sort((a, b) => {
    for (
      let i = 0;
      i < Math.min(a.treeIndexList.length, b.treeIndexList.length);
      i++
    ) {
      if (a.treeIndexList[i] !== b.treeIndexList[i])
        return a.treeIndexList[i] - b.treeIndexList[i];
    }

    return a.treeIndexList.length - b.treeIndexList.length;
  });

  treeList.forEach((item, index) => {
    item.treePosition = index;
  });
};

export const filterItemsByQuery = (
  items: (Document | Folder)[],
  query: string,
) => {
  const biggestScore = 1000000;

  function searchItemScore(item: Document | Folder) {
    // give founded by index items biggest score
    const itemIndexPos = item.tree_index.indexOf(query);

    if (itemIndexPos != -1) {
      // if not from begging decrease score
      // scoreIndex('1.2.3', '1.2') > scoreIndex('1.2.3', '2.3')
      return biggestScore - itemIndexPos;
    }

    return fuzzaldrinPlus.score(item.tree_index + " " + item.name, query);
  }

  const results: [Document | Folder, number][] = [];

  items.forEach(function (item) {
    const score = searchItemScore(item);
    if (score > 0) {
      results.push([item, score]);
    }
  });

  items = results
    .sort((a, b) => b[1] - a[1])
    .map(function (res) {
      return res[0];
    });

  return items;
};

export const useDocumentsStore = defineStore("documentsStore", () => {
  let loadingPromise: Promise<void>;

  const isLoading = ref(false);
  const isError = ref(false);

  const treeRaw = ref<GetTreeResponse | null>(null);

  const syncTree = (skipErrorAlert?: boolean): Promise<void> => {
    if (isLoading.value) return loadingPromise;

    isLoading.value = true;
    isError.value = false;

    loadingPromise = api.getTree().then(
      (tree) => {
        tree.Files.forEach((file) => {
          file.type = DOC_ITEM_TYPES.Document;
          file.viewUrl = documentViewUrl(ROOM_DATA.url, file.id);

          if (typeof file.index === "string")
            file.index = Number.parseInt(file.index);
        });

        tree.Folders.forEach((folder) => {
          folder.type = DOC_ITEM_TYPES.Folder;

          if (typeof folder.index === "string")
            folder.index = Number.parseInt(folder.index);

          Object.defineProperties(folder, {
            folders: {
              get: () => foldersByIdMap.value[folder.id],
            },
            files: {
              get: () => filesByIdMap.value[folder.id],
            },
            parent: {
              get: () =>
                folder.parent_id ? folderByIdMap.value[folder.parent_id] : null,
            },
            parents: {
              get: () => parentsByIdMap.value[folder.id],
            },
            items: {
              get: () => folderItemsByIdMap.value[folder.id],
            },
          });
        });

        setTreePosition([...tree.Folders, ...tree.Files]);

        treeRaw.value = tree;

        isLoading.value = false;
        isError.value = false;

        if (rootFolder.value) aggregateFolderChild(rootFolder.value)
      },
      (error) => {
        isLoading.value = false;
        isError.value = true;

        if (!skipErrorAlert) {
          $notifyDanger("Failed to update documents.");
        }

        return Promise.reject(error);
      },
    );

    return loadingPromise;
  };

  const rootFolder = computed(() =>
    treeRaw.value?.Folders.find((folder) => folder.parent_id === null),
  );

  const folderByIdMap = computed<Record<number, Folder>>(() => {
    return (
      treeRaw.value?.Folders.reduce<Record<number, Folder>>((result, item) => {
        result[item.id] = item;
        return result;
      }, {}) ?? {}
    );
  });

  const folderByUidMap = computed<Record<string, Folder>>(() => {
    return (
      treeRaw.value?.Folders.reduce<Record<string, Folder>>((result, item) => {
        result[item.uid] = item;
        return result;
      }, {}) ?? {}
    );
  });

  const fileByIdMap = computed<Record<number, Document>>(() => {
    return (
      treeRaw.value?.Files.reduce<Record<number, Document>>((result, item) => {
        result[item.id] = item;
        return result;
      }, {}) ?? {}
    );
  });

  const fileByUidMap = computed<Record<string, Document>>(() => {
    return (
      treeRaw.value?.Files.reduce<Record<string, Document>>((result, item) => {
        result[item.uid] = item;
        return result;
      }, {}) ?? {}
    );
  });

  const foldersByIdMap = computed(
    () =>
      treeRaw.value?.Folders.reduce<Record<number, Folder[]>>(
        (result, folder) => {
          result[folder.id] =
            treeRaw.value?.Folders.filter(
              (item) => item.parent_id === folder.id,
            ).sort((a, b) => a.treePosition - b.treePosition) ?? [];
          return result;
        },
        {},
      ) ?? {},
  );

  const filesByIdMap = computed(
    () =>
      treeRaw.value?.Folders.reduce<Record<number, Document[]>>(
        (result, folder) => {
          result[folder.id] =
            treeRaw.value?.Files.filter(
              (item) => item.folder_id === folder.id,
            ).sort((a, b) => a.treePosition - b.treePosition) ?? [];
          return result;
        },
        {},
      ) ?? {},
  );

  const getParents = (folder: Folder): Folder[] => {
    const result = [];

    while (folder.parent_id) {
      result.push(folderByIdMap.value[folder.parent_id]);
      folder = folderByIdMap.value[folder.parent_id];
    }

    return result;
  };

  const parentsByIdMap = computed<Record<number, Folder[]>>(
    () =>
      treeRaw.value?.Folders.reduce<Record<number, Folder[]>>(
        (result, item) => {
          result[item.id] = getParents(item);
          return result;
        },
        {},
      ) ?? {},
  );

  const folderItemsByIdMap = computed(
    () =>
      treeRaw.value?.Folders.reduce<Record<number, (Folder | Document)[]>>(
        (result, item) => {
          result[item.id] = [
            ...foldersByIdMap.value[item.id],
            ...filesByIdMap.value[item.id],
          ].sort((a, b) => a.treePosition - b.treePosition);
          return result;
        },
        {},
      ) ?? {},
  );

  const aggregateFolderChild = (folder: Folder) => {
    let totalFilesCount = filesByIdMap.value[folder.id].length;
    let totalFoldersCount = foldersByIdMap.value[folder.id].length;

    foldersByIdMap.value[folder.id].forEach((nested: Folder) => {
      aggregateFolderChild(nested);
      totalFilesCount += nested.aggregates.files;
      totalFoldersCount += nested.aggregates.folders;
    });

    folder.aggregates = {
      files: totalFilesCount,
      folders: totalFoldersCount,
      all: totalFilesCount + totalFoldersCount,
    };
  }

  const getFoldersTreeNode = (
    folder: Folder,
  ): TreeItem<{ id: number; name: string; parent_id: number | null }> => {
    return {
      id: folder.id,
      name: folder.name,
      parent_id: folder.parent_id,
      children: foldersByIdMap.value[folder.id].map(getFoldersTreeNode) ?? [],
    };
  };

  const foldersTree = computed<
    TreeItem<{ id: number; name: string; parent_id: number | null }>[]
  >(() => {
    if (!rootFolder.value || !treeRaw.value) return [];

    return [getFoldersTreeNode(rootFolder.value)];
  });

  const getAttachmentsTreeNode = (
    current: Folder | Document,
  ): AttachmentsTreeNode => {
    if (isDocument(current)) return {
      id: current.uid,
      label: current.name,
      type: current.type,
      name: current.name,
      mimetype: current.mimetype,
      canParent: false,
    };

    return {
      ...current,
      id: current.uid,
      label: current.name,
      canParent: true,
      children: folderItemsByIdMap.value[current.id].map(getAttachmentsTreeNode),
    };
  };

  const attachmentsTree = computed<
    TreeItem<{ label: string; canParent: boolean }>[]
  >(() => {
    if (!rootFolder.value || !treeRaw.value) return [];

    return folderItemsByIdMap.value[rootFolder.value.id].map(getAttachmentsTreeNode);
  });

  const folderPathsMap = computed<Map<number, FolderPath>>(() => {
    const paths = new Map<number, FolderPath>(); // folder id, path reversed (root at the end)

    function addPath(
      folder: TreeItem<{ id: number; name: string; parent_id: number | null }>,
    ) {
      let path = [convertToWordArray(folder.name)];

      if (folder.parent_id && paths.has(folder.parent_id)) {
        path = paths.get(folder.parent_id)!.concat(path);
      }

      paths.set(folder.id, path);

      folder.children?.forEach(addPath);
    }

    foldersTree.value.forEach(addPath);

    return paths;
  });

  const suggestUploadFolder = (fullPath: string): number | undefined => {
    const categoryPath = fullPath.split("\\").map(convertToWordArray);

    let bestScore = -Infinity;
    let bestFolderId = rootFolder.value?.id;

    for (const [folderId, folderPath] of folderPathsMap.value.entries()) {
      const folder = folderByIdMap.value[folderId];
      if (!folder.edit) continue; // upload is not allowed, skip

      const score = pathScore(categoryPath, folderPath);
      if (score > bestScore) {
        bestScore = score;
        bestFolderId = folderId;
      }
    }

    return bestFolderId;
  };

  const bookmarkItems = (payload: BookmarkItemsPayload) => {
    return api
      .bookmarkItems(payload)
      .then((response) => {
        const bookmarkValue = payload.set_bookmarked ? new Date() : null;

        response.folder_ids.forEach((folderId) => {
          const folder = folderByIdMap.value[folderId];
          folder.bookmarked = bookmarkValue;
        });

        response.document_ids.forEach((documentId) => {
          const doc = fileByIdMap.value[documentId];
          doc.bookmarked = bookmarkValue;
        });
      })
      .catch(() => {
        $notifyDanger(
          payload.set_bookmarked
            ? t("data_room.bookmark_items.failed.bookmark")
            : t("data_room.bookmark_items.failed.unbookmark")
          );
      });
  };

  const toggleBookmark = (item: Folder | Document) => {
    const payload: BookmarkItemsPayload = {
      folder_ids: [],
      document_ids: [],
      set_bookmarked: !item.bookmarked,
    };

    if (isFolder(item)) {
      payload.folder_ids.push(item.id);
    } else {
      payload.document_ids.push(item.id);
    }

    return bookmarkItems(payload);
  };

  const archiveItems = (params: FolderMenuParams) => {
    const selectedItemsText = getDocumentsSelectedItemsText(params);

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: t("data_room.archive_items.title", {count_text: getDocumentsCountText(params)}),
        message: t("data_room.archive_items.message", {selected_items: selectedItemsText}),
        confirmButtonText: t("shared.delete"),
        customClass: "el-message-box--warning",
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .archiveItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              } satisfies ArchiveItemsPayload)
              .then((response) => {
                syncTree();

                $notifySuccess(t(
                  "data_room.archive_items.success",
                  {selected_items: selectedItemsText},
                  params.documents.length + params.folders.length,
                ));

                return response;
              })
              .catch(() => {
                $notifyDanger(t("data_room.archive_items.failed", {selected_items: selectedItemsText}));
              });
          }

          resolve();
        },
      });
    });
  };

  const getDescendants = (folder: Folder): (Document | Folder)[] => {
    if (!treeRaw.value) return []

    const list = [
      ...treeRaw.value.Folders,
      ...treeRaw.value.Files,
    ]

    if (!folder.tree_index) return list

    return list.filter(item => item.tree_index.startsWith(folder.tree_index))
  }

  return {
    isLoading,
    isError,
    syncTree,
    treeRaw,
    rootFolder,
    foldersTree,
    attachmentsTree,
    folderItemsByIdMap,
    folderByIdMap,
    folderByUidMap,
    foldersByIdMap,
    fileByIdMap,
    fileByUidMap,
    filesByIdMap,
    folderPathsMap,
    suggestUploadFolder,
    bookmarkItems,
    toggleBookmark,
    archiveItems,
    getDescendants,
  };
});
