import { ApiService } from "@drVue/api-service";

export interface AddMembersData {
  members: { email: string }[];

  message: string;
}

export interface RoomMember {
  avatar: { thumbnail: string; reduced: string };
  company: string | null;
  email: string;
  first_name: string;
  headline: string;
  id: number;
  initial: string;
  is_archived: boolean;
  last_name: string;
  name: string;
  office_number: string | null;
  pgroup: { id: number; name: string; is_archived: boolean };
  room_owner?: boolean;
  title: string | null;
  uid: string;
  user_id: number;
  /** @todo Fix types below */
  pending: boolean; // there is no "pending" field in RoomMember from server, only for invites
}

export type RoomInvite = Pick<
  RoomMember,
  "email" | "id" | "initial" | "pgroup" | "uid"
> & {
  invite_date: string; // "2023-11-14T10:18:35.987000Z"
  invite_message: string | null;
  pending: boolean;
  /** @todo  All fields below just for type compatibility */
  is_archived?: undefined;
  name?: undefined;
  office_number?: undefined;
  company?: undefined;
  title?: undefined;
};

export type RoomMemberOrInvite = RoomMember | RoomInvite;

export class RoomMembersApiService extends ApiService {
  public async getMembers() {
    const url: string = this.Urls["api:room:pgroup_list_members"]();
    return this.get<RoomMemberOrInvite[]>(url);
  }

  public async addMembersCheck(
    pgroupId: number,
    addMembersData: AddMembersData,
  ) {
    const url = this.Urls["api:room:pgroup_members_check_add"](pgroupId);
    return this.post(url, addMembersData);
  }

  public async addMembers(pgroupId: number, addMembersData: AddMembersData) {
    const url = this.Urls["api:room:pgroup_members_add"](pgroupId);
    return this.post(url, addMembersData);
  }

  public async removeMember(permissionId: number, memberEmail: string) {
    const url = this.Urls["api:room:pgroup_members_remove"](
      permissionId,
      memberEmail,
    );
    return this.delete(url);
  }

  public async resendInvite(permissionId: number, inviteId: number) {
    const url = this.Urls["api:room:pgroup_resend_invite"](
      permissionId,
      inviteId,
    );
    return this.post(url, null);
  }
}
