<template>
  <ElDialog v-model="isOpened" :width="604" append-to-body @close="close">
    <template #header>
      <I18nT keypath="upload_dialog.header" tag="div" :class="$style.header">
        <template #count_text>
          {{ $t("data_room.count_text.file", {count: totalFilesCount}, totalFilesCount) }}
        </template>

        <template #folder_name>
          <b :class="$style.uploadToFolderName">{{ uploadToFolderName }}</b>
        </template>
      </I18nT>
    </template>

    <template v-if="step === 'batches'">
      <ElScrollbar :height="214" :class="$style.scroll">
        <UploadList :batches="batches" :duplicates="duplicatesMap" />
      </ElScrollbar>
      <ElForm label-position="top">
        <ElFormItem v-if="!uploadToFolderId" :label="$t('upload_dialog.upload_to_folder')" required>
          <SelectFolderControl v-model="localUploadToFolderIds" />
        </ElFormItem>
        <ElFormItem v-if="isAttachToRequestVisible" :label="$t('shared.attach_to_request')">
          <SelectRequestControl v-model="localAttachToRequestId" />
        </ElFormItem>
      </ElForm>
    </template>

    <template v-if="step === 'permissions'">
      <UploadPermissionsTable
        v-model:permissions="permissions"
        v-model:group-ids-to-notify="groupIdsToNotify"
      />
    </template>

    <template #footer>
      <div :class="$style.actions">
        <ElButton
          v-if="step === 'permissions'"
          :class="$style.backButton"
          :disabled="isUploading"
          @click="back"
        >
          {{ t("shared.back") }}
          <template #icon>
            <DrIcon name="chevron-left" />
          </template>
        </ElButton>
        <ElButton @click="close" :disabled="isUploading">{{
          t("shared.cancel")
        }}</ElButton>
        <ElButton
          :disabled="isUploading || !batches.length"
          type="primary"
          @click="buttonAction"
        >
          {{ buttonText }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup lang="ts">
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { computed, h, provide, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { FileUploadHelper } from "@app/file-upload-helper";
import { FileUploadService } from "@app/ng/serviceProxies";
import TasksAttachmentsService from "@app/ng/tasks/services/TasksAttachmentsService2";
import { ROOM_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { attachDocumentsToFinding } from "@app/vue/components/room/findings/utils";
import { DocumentsApiService } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { pinia } from "@app/vue/store/pinia";
import { useCategoriesStore } from "@app/vue/store/pinia/room/categories";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { useTasksStore } from "@app/vue/store/pinia/room/tasks";
import $utils from "@drVue/utils";
import DrIcon from "../dr-icon/DrIcon.vue";
import UploadPermissionsTable from "./permissions-table/UploadPermissionsTable.vue";
import SelectFolderControl from "./SelectFolderControl.vue";
import SelectRequestControl from "./SelectRequestControl.vue";
import {
  type Batch,
  type BatchItem,
  type DrUploadDirectory,
  type DrUploadFile,
  UploadListBatchInjectionKey,
} from "./types";
import UploadList from "./UploadList.vue";

import type {
  Document,
  Folder,
  UploadPermissions,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";

interface Props {
  items: any[] | null;
  uploadToFolderId?: number;
  attachToRequestId?: number;
  attachToFindingId?: string;
}

interface Emits {
  (e: "opened"): void;
  (e: "closed"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const documentsStore = useDocumentsStore();

const fileUploadService = new FileUploadService();
const tasksAttachmentsService = new TasksAttachmentsService();
const documentsApiService = new DocumentsApiService();
const fileUploadHelper = new FileUploadHelper();

const isAdmin = ROOM_DATA.userPermissions.administrator;
const isAttachToRequestVisible =
  !props.attachToRequestId &&
  ROOM_DATA.userPermissions.viewTasks &&
  ROOM_DATA.userPermissions.canManageTasks;

const isOpened = ref(false);
const isUploading = ref(false);

const batches = ref<Batch[]>([]);

const permissions = ref<UploadPermissions | null>(null);
const groupIdsToNotify = ref<number[]>([]);

const step = ref<"batches" | "permissions">("batches");
const buttonText = computed(() => {
  if (step.value === "batches") {
    return isAdmin ? t("shared.next") : t("shared.upload");
  } else if (step.value === "permissions") {
    return t("shared.upload");
  } else {
    throw new Error("How did we get here?");
  }
});

const buttonAction = () => {
  if (step.value === "batches") {
    if (isAdmin) {
      step.value = "permissions";
    } else {
      upload();
    }
  } else if (step.value === "permissions") {
    upload();
  } else {
    throw new Error("How did we get here?");
  }
};

const back = () => {
  if (step.value === "permissions") {
    step.value = "batches";
  }
};

const initialFoldersIds = props.uploadToFolderId
  ? [props.uploadToFolderId]
  : documentsStore.rootFolder?.id
    ? [documentsStore.rootFolder.id]
    : [];
const localUploadToFolderIds = ref<number[]>(initialFoldersIds);
const localUploadToFolderId = computed(() => localUploadToFolderIds.value[0]);

const initialRequestsIds = props.attachToRequestId
  ? [props.attachToRequestId]
  : [];
const localAttachToRequestId = ref<number[]>(initialRequestsIds);

const uploadToFolderName = computed(() => {
  if (!localUploadToFolderIds.value || !localUploadToFolderIds.value.length) {
    return null;
  }

  const folderId = localUploadToFolderIds.value[0];
  return documentsStore.folderByIdMap[folderId].name;
});

const attachToRequest = computed(() => {
  if (!localAttachToRequestId.value) return null;
  return tasksStore.tasks[localAttachToRequestId.value[0]] ?? null;
});

const upload = () => {
  if (!localUploadToFolderId.value) return;

  let allBatchItems: BatchItem[] = [];

  batches.value.forEach((batch) => {
    // We use the original name to check if the name was changed by the user.
    // If it was, we rename the batch. Renaming the batch means
    // renaming all items in the batch and updating their paths.
    if (batch.name !== batch.originalName) {
      fileUploadHelper.renameBatch(batch, batch.name);
    }

    allBatchItems = allBatchItems.concat(batch.items);
  });

  const uploadPermissions = isAdmin
    ? permissions.value?.default_permissions
    : undefined;

  isUploading.value = true;
  fileUploadService
    .uploadToFolder(
      localUploadToFolderId.value,
      allBatchItems,
      uploadPermissions,
      groupIdsToNotify.value,
    )
    .then((result) => {
      if (attachToRequest.value) {
        tasksAttachmentsService.attachUploadBatchResult(
          attachToRequest.value,
          result,
        );
      }

      if (props.attachToFindingId) {
        attachDocumentsToFinding(
          props.attachToFindingId,
          result.info.folders.map((f: Folder) => ({ folder_uid: f.uid })),
          result.files.map((f) => ({ document_uid: f.data.uid })),
        );
      }
    });

  // Close will set isUploading to false after the dialog is closed.
  close();
};

const totalFilesCount = computed(() => {
  return batches.value.reduce((acc, batch) => {
    if (batch.type === "file") return acc + 1;
    if (batch.type === "folder") return acc + batch.items.length;

    return acc;
  }, 0);
});

const duplicatesMap = computed(() => {
  const docs = documentsStore.treeRaw!.Files;

  return batches.value.reduce<Map<Batch, Document>>((map, batch) => {
    if (batch.type !== "file") return map;

    // We have only one file in the "file" batch, even though "items" is an array.
    const batchFile = batch.items[0];

    type Candidate = { score: number; doc: Document };
    const candidates = docs.reduce<Candidate[]>((acc, doc) => {
      if (doc.size !== batchFile.size) return acc;

      const score = fuzzaldrinPlus.score(doc.name, batchFile.name);
      if (!score) return acc;

      acc.push({ score, doc });

      return acc;
    }, []);
    if (!candidates.length) return map;

    candidates.sort((a, b) => b.score - a.score);
    map.set(batch, candidates[0].doc);

    return map;
  }, new Map<Batch, Document>());
});

const linkBatch = (batch: Batch) => {
  if (!attachToRequest.value) return;

  const duplicate = duplicatesMap.value.get(batch);
  if (!duplicate) return;

  tasksAttachmentsService
    .createAttachments(
      attachToRequest.value,
      [],
      [{ document_id: duplicate.id }],
    )
    .then(
      () => {
        batches.value = batches.value.filter((b) => b.id !== batch.id);
        $notifySuccess(t("requests.file_link_success"));
      },
      () => {
        $notifyDanger(t("requests.file_link_failure"));
      },
    );
};

if (!isAttachToRequestVisible && attachToRequest.value) {
  const itemPath =
    categoriesStore.categories[attachToRequest.value?.category_id].full_path;

  const suggested = documentsStore.suggestUploadFolder(itemPath);

  if (suggested) localUploadToFolderIds.value = [suggested];
}

watch(
  localUploadToFolderId,
  (folderId) => {
    // If the user is not an admin, we don't need to fetch permissions.
    if (!folderId || !isAdmin) {
      permissions.value = null;
      return;
    }

    documentsApiService.getFolderDefaultPermissions(folderId).then((p) => {
      permissions.value = p;
    });
  },
  { immediate: true },
);

// This is the entry point for the dialog.
const open = (items: File[] | (DrUploadFile | DrUploadDirectory)[]) => {
  batches.value = fileUploadHelper.splitToBatches(items);

  isOpened.value = true;

  emit("opened");
};

const close = () => {
  isOpened.value = false;

  setTimeout(() => {
    batches.value = [];
    permissions.value = null;
    step.value = "batches";
    isUploading.value = false;

    emit("closed");
  }, 300);
};

watch(
  () => props.items,
  (items) => {
    if (items) open(items);
  },
  {
    immediate: true,
    deep: true,
  },
);

const getDuplicatePrimaryButtonText = (
  batch: Batch,
  duplicate: Document | undefined,
) => {
  if (props.attachToRequestId) return t("requests.attach_existing_file");
  else return t("requests.skip_uploading");
};

const onBatchAction = computed(() => (batch: Batch) => {
  if (props.attachToRequestId) {
    linkBatch(batch);
  } else {
    // Skip the file
    batches.value = batches.value.filter((b) => b.id !== batch.id);
  }
});

const onBatchRenamed = computed(() => (renamedBatch: Batch) => {
  const index = batches.value.findIndex((b) => b.id === renamedBatch.id);
  if (index === -1) return;

  batches.value[index] = renamedBatch;
});

provide(UploadListBatchInjectionKey, {
  getDuplicatePrimaryButtonText,
  onBatchRenamed,
  onBatchAction,
});

defineExpose({ open, close });
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.header {
  font-size: 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.backButton {
  margin-right: auto;

  &:global(.el-button) {
    --el-button-bg-color: transparent;
    --el-button-border-color: transparent;
    --el-button-disabled-bg-color: transparent;
    --el-button-disabled-border-color: transparent;
    font: typography.$body_regular;
  }
}

.scroll {
  margin-bottom: 20px;
  border: 1px solid colors.$pr-300;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.uploadToFolderName {
  color: colors.$pr-900;
}
</style>
