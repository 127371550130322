import { cloneDeep } from "lodash-es";
import { sortBy } from "lodash-es";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import processCategories from "@app/ng/tasks/services/helpers/processCategories";
import { DrStore } from "@app/vue";
import { $notifyDanger, createDictionary } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesArchivedStore } from "@drVue/store/pinia/room/categoriesArchived";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { CategoriesApiService } from "./api";

import type { Category } from "./api";
import type { Dictionary } from "@drVue/types";
import type { NavTreeItem } from "@shared/ui/dr-nav";
import type { Progress } from "@shared/ui/dr-progress-bar/types";

const api = new CategoriesApiService();

type CategoryProgress = Progress & {
  resolvedType: number;
};

type CategoriesProgressMap = { [key in Category["id"]]: CategoryProgress };

const getCategoriesProgressCounter = (
  categoriesList: Category[],
  tasksStore: ReturnType<typeof useTasksStore>,
) => {
  const taskStatusesList = DrStore.state.room.tasksStatuses.list;
  const baseCategoryCounter: CategoryProgress = {
    total: 0,
    resolvedType: 0,
    items: taskStatusesList.map((status) => {
      return {
        name: status.name,
        color: status.color,
        count: 0,
      };
    }),
  };
  const taskStatusIdToIndex: Dictionary<number> = taskStatusesList.reduce(
    (acc, status, index) => {
      acc[status.id] = index;
      return acc;
    },
    {} as Dictionary<number>,
  );
  const resolvedStatues = taskStatusesList
    .filter((status) => status.type === "resolved")
    .reduce((acc, status) => {
      acc[status.id] = true;
      return acc;
    }, {} as Dictionary<boolean>);

  // init counter for each category with zero values
  const progressPerCategories = categoriesList.reduce((acc, curCat) => {
    acc[curCat.id] = cloneDeep(baseCategoryCounter);
    return acc;
  }, {} as CategoriesProgressMap);

  // update counter for direct child tasks
  for (const task of tasksStore.tasksList) {
    const counter = progressPerCategories[task.category_id];

    /**
     * After deleting a category, the tasks store update request has not yet been executed,
     * so a case arises when a task from a deleted category refers to a missing element.
     */
    if (counter) {
      counter.total++;

      const statusIndex = taskStatusIdToIndex[task.status_id];
      counter.items[statusIndex].count++;

      if (resolvedStatues[task.status_id]) counter.resolvedType++;
    }
  }
  // update counter for parent categories based on child categories
  for (const parentCat of categoriesList) {
    const parentCounter = progressPerCategories[parentCat.id];
    for (const childId of parentCat.descendants) {
      if (childId == parentCat.id) continue; // descendant includes itself
      const childCounter = progressPerCategories[childId];
      parentCounter.total += childCounter.total;
      parentCounter.resolvedType += childCounter.resolvedType;
      parentCounter.items.forEach((item, index) => {
        item.count += childCounter.items[index].count;
      });
    }
  }
  return progressPerCategories;
};

const formatCategory = (
  cat: Category,
  categoriesProgress: CategoriesProgressMap,
): NavTreeItem<Pick<Category, "order" | "uid">> => {
  const progress = categoriesProgress[cat.id];
  return {
    id: cat.id,
    uid: cat.uid,
    name: cat.name,
    order: cat.order,
    progress,
    counter: `${progress.resolvedType}/${progress.total}`,
    children: cat.categories.map((child) =>
      formatCategory(child, categoriesProgress),
    ),
  };
};

export const useCategoriesStore = defineStore("categoriesStore", () => {
  const categoriesArchivedStore = useCategoriesArchivedStore(pinia);
  const tasksStore = useTasksStore(pinia);

  const loadingPromise = ref<Promise<Category[]> | null>(null);

  const categoriesList = ref<Category[]>([]);
  const categories = computed(() => {
    return categoriesList.value.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, createDictionary<Category>());
  });

  const categoriesByUid = computed(() => {
    return categoriesList.value.reduce((acc, cur) => {
      acc[cur.uid] = cur;
      return acc;
    }, createDictionary<Category>());
  });

  const categoriesNavTree = computed(() => {
    if (!categoriesList.value.length) return [];

    const categoriesProgress = getCategoriesProgressCounter(
      categoriesList.value,
      tasksStore,
    );

    return rootCategories.value.map((cat) =>
      formatCategory(cat, categoriesProgress),
    );
  });

  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const rootCategories = computed<Category[]>(() => {
    const root = categoriesList.value.filter((cat) => !cat.parent_id);
    return sortBy(root, "order");
  });

  const childIdToRoot = computed(() =>
    rootCategories.value.reduce<Dictionary<number>>((acc, cur) => {
      // category itself is in descendants
      cur.descendants.forEach((catId) => (acc[catId] = cur.id));
      return acc;
    }, {}),
  );

  const load = (skipErrorAlert = false) => {
    if (isLoading.value && loadingPromise.value) return loadingPromise.value;

    isLoading.value = true;
    isError.value = false;

    loadingPromise.value = api
      .getAllCategories()
      .then(
        (cats) => {
          if (!Array.isArray(cats)) return [];

          cats = processCategories(cats);

          cats.forEach(
            (c) => (c.expanded = categories.value[c.id]?.expanded ?? false),
          );
          categoriesList.value = cats;

          return cats;
        },
        (error) => {
          if (!skipErrorAlert) {
            $notifyDanger("Failed to load categories.");
          }

          isError.value = true;
          return Promise.reject(error);
        },
      )
      .finally(() => (isLoading.value = false));

    return loadingPromise.value;
  };

  const getCategoryParents = (id: Category["id"]) =>
    categoriesList.value.filter((cat) => cat.descendants.includes(id));

  const createCategory = async (payload: {
    name: Category["name"];
    parent_id?: Category["parent_id"];
    key?: Category["key"];
  }) => {
    const newCategory = await api.createCategory(payload);
    // TODO: refactor `load` so manual updates of lists are not needed
    categoriesList.value = processCategories([
      ...categoriesList.value,
      newCategory,
    ]);
    return newCategory;
  };

  // TODO: refactor `load` so manual updates of lists are not needed
  const editCategory = async (
    id: Category["id"],
    payload: Partial<Category>,
  ) => {
    const updatedCategory = await api.editCategory(id, payload);
    const updatedCategories = categoriesList.value.map((cat) =>
      cat.id === id ? updatedCategory : cat,
    );

    categoriesList.value = processCategories(updatedCategories);

    return updatedCategory;
  };

  const archiveCategory = async (category: Category) => {
    await api
      .editCategory(category.id, { is_archived: true })
      .then(() => Promise.all([load(), categoriesArchivedStore.load(true)]))
      .catch(() => {
        $notifyDanger(
          `Failed to delete ${category.parent ? "category" : "worklist"}.`,
        );
      });
  };

  const moveCategory = async (
    category: Category,
    params: {
      parent_id?: Category["parent_id"] | null;
      order: Category["order"];
    },
  ) => {
    return api.editCategory(category.id, params).then(
      async (updatedCategory) => {
        await load(true);
        return updatedCategory;
      },
      () => {
        $notifyDanger(
          `Failed to move ${category.parent ? "category" : "worklist"}.`,
        );
      },
    );
  };

  return {
    loadingPromise,

    categories,
    categoriesList,
    categoriesByUid,

    rootCategories,
    childIdToRoot,
    categoriesNavTree,

    isLoading,
    isError,

    load,
    getCategoryParents,
    createCategory,
    editCategory,
    archiveCategory,
    moveCategory,
  };
});

export type CategoriesStore = ReturnType<typeof useCategoriesStore>;
