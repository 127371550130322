import { keyBy } from "lodash-es";
import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";
import { UserEmail, UserOfficeNumber } from "@shared/ui/users";

import { t } from "@drVue/i18n";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import NameCell from "./MembersTableNameCell.vue";

import type { MembersTableColumn, MembersTableRow } from "../types";
import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef } from "vue";

export default class TableColumns extends TableColumnsBase<MembersTableRow> {
  constructor(private readonly viewColumns: ComputedRef<CustomViewColumn[]>) {
    super();
  }

  /** just to fix requires of inlineEditor in TableColumnsBase */
  inlineEditor = new TableInlineEditor(
    (_changes: MembersTableRow, _member: MembersTableRow) => {
      return Promise.resolve();
    },
    "id",
  );

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): MembersTableColumn[] {
    return [
      this.nameColumn(),
      this.emailColumn(),
      this.phoneColumn(),
      this.companyColumn(),
      this.roleColumn(),
      this.groupColumn(),
    ];
  }

  private nameColumn(): MembersTableColumn {
    return {
      field: "name",
      title: t("permissions.fields.name"),
      minWidth: 140,
      width: 312,
      sortable: true,
      showOverflow: "ellipsis",
      slots: {
        default: ({ row }) => h(NameCell, { userUid: row.uid }),
      },
    };
  }

  private emailColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "email",
      title: t("permissions.fields.email"),
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => {
          return [h(UserEmail, { email: row.email, isIconShowed: false })];
        },
      },
    };
  }

  private phoneColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "office_number",
      title: t("permissions.fields.phone"),
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => {
          return [
            h(UserOfficeNumber, {
              officeNumber: row.office_number || "",
            }),
          ];
        },
      },
    };
  }

  private companyColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "company",
      title: t("permissions.fields.company"),
      minWidth: 150,
      width: 150,
    };
  }

  private roleColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "title",
      title: t("permissions.fields.title"),
      minWidth: 150,
      width: 150,
    };
  }

  private groupColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "pgroup",
      title: "Group",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => row.pgroup.name,
      },
    };
  }
}
