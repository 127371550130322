<template>
  <ElDrawer
    ref="elDrawerRef"
    v-model="isVisible"
    append-to-body
    class="el-drawer--close el-drawer--auto"
    :with-header="false"
    :modal="!noBackdrop"
    :size="size"
    @opened="emit('opened')"
    @close="handleClose()"
    @closed="emit('close')"
  >
    <DrDrawerClose @close="handleClose()" />
    <DocumentVersionQA
      ref="docVerQaRef"
      v-if="docInfo"
      has-header
      :doc-id="docInfo.id"
      :doc-name="docInfo.name"
      :doc-uid="docInfo.uid"
      :doc-index="docInfo.index"
      :doc-mimetype="docInfo.mimetype"
    />
    <div v-else>Failed to load Document AI. Invalid document.</div>
  </ElDrawer>
</template>

<script lang="ts" setup>
import { ElDrawer } from "element-plus";
import { nextTick, onMounted, ref, watch } from "vue";
import { DrDrawerClose } from "@shared/ui/dr-drawer-close";

import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import {
  disableElBackdrop,
  resetElBackdrop,
} from "@app/vue/utils/disableElBackdrop";
import DocumentVersionQA from "./DocumentVersionQA.vue";
import { DEFAULT_TAB, type TabIds } from "./types";

interface Props {
  docId: number;
  noBackdrop?: boolean;
  size?: string;
  initTab?: TabIds;
}

const props = withDefaults(defineProps<Props>(), {
  size: "",
});

interface Emits {
  (e: "opened"): void;
  (e: "close"): void;
}

const emit = defineEmits<Emits>();

interface AiDocument {
  id: number;
  uid: string;
  index: string;
  mimetype: string;
  name: string;
}

const documentsStore = useDocumentsStore();

const elDrawerRef = ref<typeof ElDrawer | null>(null);
const docVerQaRef = ref<typeof DocumentVersionQA | null>(null);

const isVisible = ref(false);
const docInfo = ref<AiDocument | null>(null);

const open = (tab: TabIds = DEFAULT_TAB) => {
  if (props.noBackdrop && elDrawerRef.value) {
    disableElBackdrop(elDrawerRef.value);
  }

  isVisible.value = true;

  nextTick(() => {
    docVerQaRef.value?.openTab(tab);
  });
};

const handleClose = () => {
  if (props.noBackdrop && elDrawerRef.value) {
    resetElBackdrop(elDrawerRef.value);
  }

  isVisible.value = false;
};

onMounted(() => {
  open(props.initTab);
});

watch(
  () => props.docId,
  () => {
    const doc = props.docId && documentsStore.fileByIdMap[props.docId];
    if (doc) {
      docInfo.value = {
        id: doc.id,
        uid: doc.uid,
        index: doc.tree_index,
        mimetype: doc.mimetype,
        name: doc.name,
      };
    } else {
      docInfo.value = null;
    }
  },
  { immediate: true },
);

defineExpose({
  open,
});
</script>
