<template>
  <DrPopup ref="popupRef" paddingless @hide="handleHide" @show="handleShow">
    <template #default="{ hide }">
      <DrPanelWrapper :width="354">
        <DrDynamicFormClassic
          ref="form"
          :entity="model"
          :schema="nameSchema"
          :errors="formErrors"
          :disabled="isFormSubmitting"
          @update="handleUpdateForm"
          @submit="submit"
        />

        <div :class="$style.footer">
          <ElButton size="small" @click="hide">{{
            t("shared.cancel")
          }}</ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="isSubmitDisabled"
            :loading="isFormSubmitting"
            @click="submit"
          >
            {{ labels.submitText }}
          </ElButton>
        </div>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@drVue/shared/ui/dr-dynamic-form";
import { validateString } from "@drVue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@drVue/shared/ui/dr-form";
import { DrPanelWrapper } from "@drVue/shared/ui/dr-panels";
import { DrPopup } from "@drVue/shared/ui/dr-popups";
import DrStore from "@drVue/store";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

type OpenPopupPayload =
  | {
      type: "create";
      group?: Group;
    }
  | {
      type: "rename" | "copy";
      group: Group;
    };

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

interface Emit {
  (event: "created", group: Group): void;
}

const emit = defineEmits<Emit>();

const { t } = useI18n();

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetError } =
  useFormHelper<{ name: string }>();

const popupRef = ref<InstanceType<typeof DrPopup>>();
const form = ref<InstanceType<typeof DrDynamicFormClassic> | undefined>();

const actionType = ref<OpenPopupPayload["type"]>("create");
const initialModelValue = ref("");
const modelValue = ref("");
const group = ref<Group | undefined>();
const hideModalCallback = ref<() => void | undefined>();
const showModalCallback = ref<() => void | undefined>();

const model = computed(() => ({
  name: modelValue.value,
}));

const isSubmitDisabled = computed(() => {
  const name = modelValue.value.trim();

  return (
    !name.length ||
    name.length > 64 ||
    name === initialModelValue.value ||
    !!formErrors.value.name
  );
});

const labels = computed(() => {
  let submitText = t("shared.create");
  if (actionType.value === "rename") {
    submitText = t("shared.rename");
  }
  if (actionType.value === "copy") {
    submitText = t("shared.copy");
  }
  return {
    title: t("shared.name"),
    placeholder: t("nav.group_name"),
    submitText,
  };
});

const nameSchema = computed(() => {
  const schema: FormSchema = [
    {
      type: FieldSchemaType.Text,
      isReadOnly: isFormSubmitting.value,
      prop: "name",
      label: labels.value.title,
      placeholder: labels.value.placeholder,
      rules: validateString()
        .required(t("form.is_required", { field: "name" }))
        .max(64, t("form.max_length", { max: 64 })),
      extra: {
        autofocus: 200,
      },
    },
  ];

  return schema;
});

const handleUpdateForm = ({
  field,
  value,
}: {
  field: string;
  value: string;
}) => {
  resetError(field);
  if (field === "name") modelValue.value = value;
};

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);

  if (result !== true) return;

  let promise: Promise<undefined | Group>;
  const initialValue = initialModelValue.value.trim();
  const newValue = modelValue.value.trim();

  if (actionType.value === "create") {
    const payload = {
      name: newValue,
      is_administrator: false,
    };
    promise = DrStore.dispatch("room/groups/create", payload).then(
      (createdGroup: Group) => {
        $notifySuccess(
          t("permissions.group_created", {
            group: newValue,
          }),
        );
        return createdGroup;
      },
    );
  } else if (actionType.value === "copy") {
    const payload = {
      pgroupId: group.value!.id,
      data: {
        name: newValue,
        is_administrator: false,
      },
    };
    promise = DrStore.dispatch("room/groups/copy", payload).then(() => {
      $notifySuccess(
        t("permissions.group_copied", {
          group: initialValue,
          new_group: newValue,
        }),
      );
      return undefined;
    });
  } else {
    const payload = {
      pgroupId: group.value!.id,
      data: {
        name: newValue,
      },
    };
    promise = DrStore.dispatch("room/groups/edit", payload).then(() => {
      $notifySuccess(
        t("permissions.group_renamed", {
          name: initialValue,
          new_name: newValue,
        }),
      );
      return undefined;
    });
  }

  hookFormSubmitPromise(
    promise,
    actionType.value === "create"
      ? t("permissions.failed_to_create_group")
      : actionType.value === "rename"
        ? t("permissions.failed_to_rename_group")
        : t("permissions.failed_to_copy_group"),
  ).then(async (group?: Group) => {
    await DrStore.dispatch("room/groups/load");
    if (actionType.value === "create") {
      emit("created", group!);
    }
    popupRef.value?.hide();
  });
};

const show = (
  reference: HTMLElement,
  payload: OpenPopupPayload,
  params?: OpenPopupParams,
) => {
  actionType.value = payload.type;
  initialModelValue.value = payload.group?.name ?? "";
  modelValue.value = payload.group?.name ?? "";
  group.value = payload.group;

  if (payload.type === "copy") {
    modelValue.value = `${payload.group?.name} (1)`;
  }

  popupRef.value?.show(reference);
  hideModalCallback.value = params?.closedCallback;
  showModalCallback.value = params?.openedCallback;
};

const handleHide = () => {
  hideModalCallback.value?.();
};

const handleShow = () => {
  showModalCallback.value?.();
};

defineExpose({ show });
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
