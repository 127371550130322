<template>
  <div :class="$style.layout">
    <div v-if="title" :class="$style.title">{{ title }}</div>

    <div v-if="useSearch || action" :class="$style.toolbar">
      <div :class="$style.toolbarSection">
        <slot name="toolbar">
          <DrToolbarFilterSearch
            v-model="innerSearch"
            @update:model-value="emit('search', $event)"
          />
        </slot>
      </div>
      <div :class="$style.toolbarSection">
        <slot name="toolbar-right">
          <ElDropdown
            v-if="action?.menu?.length"
            trigger="click"
            @command="emit('action-menu', $event)"
          >
            <ElButton :class="$style.menuBtn">
              <DrIcon name="ellipsis-h" size="lg" />
            </ElButton>
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="item in action.menu"
                  :key="item.id"
                  :command="item.id"
                >
                  {{ item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>

          <ElButton v-if="action" type="primary" @click="$emit('action')">
            {{ action.label }}
          </ElButton>
        </slot>
      </div>
    </div>

    <div :class="$style.body">
      <slot name="default" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrToolbarFilterSearch } from "@shared/ui/dr-toolbar";

interface Props {
  title?: string;
  search?: string;
  action?: {
    label: string;
    menu?: {
      id: string;
      label: string;
    }[];
  };
}

interface Emits {
  (event: "search", phrase: string): void;
  (event: "action"): void;
  (event: "action-menu", id: string): void;
}

const { title = "", search = undefined } = defineProps<Props>();
const emit = defineEmits<Emits>();

const useSearch = computed(() => search !== undefined);
const innerSearch = ref(search || "");
watch(
  () => search,
  (value?: string) => {
    if (innerSearch.value !== value) {
      innerSearch.value = value ?? "";
    }
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.title {
  flex: 0 0 auto;
  font: typo.$title_bold;
}

.menuBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
  width: 32px;
}

.toolbar {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: spacing.$xs;
  padding: 0 0 spacing.$m;

  .title + & {
    padding-top: spacing.$s;
  }
}

.toolbarSection {
  position: relative;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: spacing.$xs;
  justify-content: start;
  white-space: nowrap;
  // to display the outline correctly when the buttons have focus
  margin-left: -8px;
  padding-left: 8px;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  & + & {
    // to display the outline correctly when the buttons have focus
    margin-left: 0;
    margin-right: -8px;
    padding-right: 8px;
  }
}

.body {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  border: 1px solid colors.$pr-150;
  border-radius: 7px;

  .title + & {
    margin-top: spacing.$s;
  }
}
</style>
