<template>
  <div :class="$style.container">
    <DrAvatar v-bind="avatar" />
    <span>{{ avatar.name }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { type Avatar, DrAvatar } from "@shared/ui/dr-avatar";

import { DrStore } from "@app/vue";

interface Props {
  userUid: string;
}

const { userUid } = defineProps<Props>();

const avatar = computed(() => {
  const avatar: Avatar = {
    url: "",
    name: "-",
    identifier: userUid,
  };

  const member = DrStore.state.room.members.membersByUid[userUid];
  if (member) {
    avatar.identifier = member.email;
    avatar.url = member.avatar.thumbnail;
    avatar.name = member.name;
  }

  if (!member) {
    const invitedUser = DrStore.state.room.members.invitesByUid[userUid];
    if (invitedUser) {
      avatar.identifier = invitedUser.email;
      avatar.name = invitedUser.initial;
    }
  }
  return avatar;
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.container {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xs;
  font: typo.$body_regular;
  color: colors.$pr-900;
}
</style>
