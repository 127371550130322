<template>
  <div :class="$style.wrapper">
    <div
      v-if="!!title || filterAllowed"
      :class="{
        [$style.header]: true,
        [$style.header_isHighlighted]: highlightHeader,
      }"
    >
      <div v-if="!!title" :class="$style.title">{{ title }}</div>
      <div v-if="filterAllowed" :class="$style.filter">
        <ElInput
          v-model="filterInputText"
          :placeholder="t('shared.search_dots')"
          @input="updateSearchTextByInput"
        >
          <template #prefix>
            <DrIcon size="sm" name="search" />
          </template>
        </ElInput>
      </div>
    </div>

    <div :class="$style.body">
      <ElScrollbar
        ref="scrollbar"
        :max-height="optionsListMaxHeight"
        :view-class="$style.optionsList"
        @scroll="handleScroll"
      >
        <TransitionGroup
          type="animation"
          :enter-active-class="$style.option_enterActive"
          :leave-active-class="$style.option_leaveActive"
        >
          <div v-for="option in filteredOptions" :key="option.id">
            <DrTooltip :content="option.tooltip">
              <button
                :class="{
                  [$style.optionItem]: true,
                  [$style.optionItem_checkboxRight]: [
                    'checkboxRight',
                    'iconsWithCheckbox',
                  ].includes(type),
                  [$style.optionItem_isSelected]: option.isSelected,
                }"
                :disabled="option.disabled"
                @click="selectOption(option)"
              >
                <div
                  v-if="type !== 'icons'"
                  :class="{
                    [$style.checkbox]: true,
                    [$style.optionItem_hover]: !hideCheckHover,
                  }"
                >
                  <DrIcon size="sm" name="check" />
                </div>

                <div v-if="type !== 'checkbox'" :class="$style.icon">
                  <slot name="icon" v-bind="{ option }">
                    <DrIcon v-if="option.icon" :name="option.icon" size="sm" />
                    <component
                      v-else-if="option.iconComponent"
                      :is="option.iconComponent"
                      class="el-icon"
                    />
                  </slot>
                </div>

                <div :class="$style.text">
                  {{ option.name }}
                </div>
              </button>
            </DrTooltip>
          </div>

          <div v-if="showNoMatches" :class="$style.noItems">
            {{ t("filters.no_matches_for_query", { query: filterSearchText }) }}
          </div>
          <div v-if="!items.length" :class="$style.noItems">
            {{ t("shared.no_items") }}
          </div>
        </TransitionGroup>
      </ElScrollbar>
    </div>

    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends SelectOptionItem">
/**
 * This component provides a unified view of the options to choose from
 * according to our style guide.
 *
 * Designed for cases where the source is not a native `select` (ElSelect),
 * but a button or a more custom component, which displays options to select.
 * A prime example is filter selectors in a pipeline.
 */

import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useThrottleFn } from "@vueuse/core";

import { DrIcon } from "../dr-icon";
import { DrTooltip } from "../dr-tooltip";

import type { SelectOptionItem, SelectOptionType } from "./types";

interface Props {
  title?: string;
  filterable?: boolean;
  type?: SelectOptionType;
  items: T[];
  hideCheckHover?: boolean;
}

const OPTIONS_WITHOUT_SCROLL_COUNT = 9;
const OPTION_ITEM_HEIGHT = 32; // which is $base-input-height (see <style>)
const OPTION_ITEM_SPACE = 4;

const props = withDefaults(defineProps<Props>(), {
  title: "",
  filterable: false,
  type: "checkbox",
});

const emit = defineEmits<{
  (e: "select", optionId: T["id"]): void;
}>();

const { t } = useI18n();

const filterInputText = ref("");
const filterSearchText = ref(""); // separating input and search value
const scroll = ref(0);

const updateSearchTextByInput = useThrottleFn(() => {
  filterSearchText.value = filterInputText.value;
}, 300);

const filteredOptions = computed(() => {
  if (filterSearchText.value) {
    const searchText = filterSearchText.value.toLocaleLowerCase();

    return props.items.filter((option) => {
      const optionName = option.name.toLocaleLowerCase();
      return optionName.includes(searchText);
    });
  }

  return props.items;
});

const showNoMatches = computed(() => {
  return (
    filterSearchText.value &&
    props.items.length &&
    !filteredOptions.value.length
  );
});

const highlightHeader = computed(() => {
  return scroll.value > 0;
});

/**
 * Use item height and spacing between elements to calculate value for ElScrollbar.
 * Use half of item height to see part of the next element, so it will immediately
 * be clear that there are more elements.
 */
const optionsListMaxHeight = computed(() => {
  return (
    OPTION_ITEM_HEIGHT * (OPTIONS_WITHOUT_SCROLL_COUNT + 0.5) +
    OPTION_ITEM_SPACE * OPTIONS_WITHOUT_SCROLL_COUNT
  );
});

const filterAllowed = computed(() => {
  return (
    props.filterable && props.items.length > OPTIONS_WITHOUT_SCROLL_COUNT + 1
  );
});

const handleScroll = ({ scrollTop }: { scrollTop: number }) => {
  scroll.value = scrollTop;
};

const selectOption = (option: T) => {
  if (option.disabled) return;

  emit("select", option.id);

  if ("action" in option && typeof option.action === "function") {
    option.action?.();
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

.wrapper {
  position: relative;
  width: auto;
  margin: calc(var(--el-popover-padding) * -1);

  border-radius: 6px;
}

.header {
  position: sticky;
  top: 0;
  padding-bottom: spacing.$s;
  z-index: 100;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.header_isHighlighted {
  border-bottom: solid 1px colors.$pr-200;
  box-shadow: 0px 2px 10px rgba(9, 25, 56, 0.04);
}

.title {
  padding: spacing.$xs spacing.$m 0;
  font: typo.$body_semibold;
  color: colors.$pr-900;
}

.filter {
  padding: spacing.$xs spacing.$m 0;
}

.body {
  padding: spacing.$s 0;

  .header + & {
    padding-top: 0;
  }

  :global {
    /**
     * Weird bug fix: horizontal scroll appears if you filter the list of options 
     *                and then clear the filter to show all options 
     *                 -> that's when the horizontal scroll starts to show...
     *                So just hide it.
     */
    .el-scrollbar__bar.is-horizontal {
      visibility: hidden;
    }
  }
}

.footer {
  padding: spacing.$xs spacing.$s;
  border-top: solid 1px colors.$pr-200;
  text-align: center;
}

.optionsList {
  padding: 0 spacing.$s;
  display: flex;
  flex-direction: column;
  gap: spacing.$xxs;
}

@keyframes optionEnter {
  0% {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
  }

  50% {
    opacity: 0;
    height: values.$base-input-height;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    height: values.$base-input-height;
    transform: translateX(0);
  }
}

.optionItem {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  height: values.$base-input-height;
  width: 100%;
  padding: spacing.$xxs;
  overflow: hidden;
  border: none;
  text-align: start;

  color: colors.$pr-900;
  font: typo.$body_regular;
  background-color: transparent;
  border-radius: values.$base-border-radius;
  cursor: pointer;

  transition-timing-function: ease-out;
  transition-duration: 200ms;
  transition-property: background-color, color;

  &:not(:disabled):hover {
    background-color: colors.$pr-100;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.optionItem_isSelected {
  font: typo.$body_medium;
  color: colors.$sc-600;
}

.optionItem_checkboxRight {
  grid-template-columns: min-content 1fr min-content;

  .checkbox {
    order: 3;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 spacing.$xxs;
  color: colors.$pr-400;

  .optionItem:not(:disabled):hover &,
  .optionItem_isSelected & {
    color: colors.$sc-600;
  }
}

.text {
  padding: 0 spacing.$xxs;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: inherit;
  color: inherit;
  user-select: none;
}

.checkbox {
  padding: 0 spacing.$xxs;
  color: colors.$pr-350;
  opacity: 0;
  transition-timing-function: ease-out;
  transition-duration: 200ms;
  transition-property: opacity, color;

  .optionItem_isSelected & {
    color: colors.$sc-600;
  }

  .optionItem:hover &.optionItem_hover,
  .optionItem_isSelected & {
    opacity: 1;
  }
}

.option_enterActive {
  animation: optionEnter 0.3s ease-in-out;
}
.option_leaveActive {
  animation: optionEnter 0.3s ease-in-out reverse;
}

.noItems {
  color: colors.$pr-900;
  font: typo.$body_regular;
  text-align: center;

  & > b {
    font: typo.$body_semibold;
  }
}
</style>
