<template>
  <DrVxeGrid
    :sort-config="tableSortConfig"
    :columns="tableColumns.columns"
    :data="items"
    @cell-click="handleCellClick"
    @sort-change="handleColumnSortChanged"
    @resizable-change="handleColumnSizeChanged"
  />
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es";
import { computed } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import { useStorage } from "@vueuse/core";

import TableColumns from "./tableColumns";

import type { MembersTableField, MembersTableRow } from "../types";
import type { CustomViewColumn } from "@setups/types";
import type {
  VxeTableDefines,
  VxeTableEvents,
  VxeTablePropTypes,
} from "vxe-table";

type SortOrder = "asc" | "desc";
type StoredSettingsColumn = CustomViewColumn & {
  sort?: SortOrder;
};
type StoredSettings = Record<MembersTableField, StoredSettingsColumn>;

const MEMBERS_TABLE_STORED_SETTINGS_KEY = "dr:members_table_stored_settings";
const MEMBERS_TABLE_STORED_SORTED_KEY = "dr:members_table_stored_sorted_field";

interface Props {
  items: MembersTableRow[];
}

interface Emits {
  (event: "select", id: MembersTableRow["uid"]): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const generateSettingsColumn = (key: MembersTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});

const currentTableSortedField = useStorage<MembersTableField>(
  MEMBERS_TABLE_STORED_SORTED_KEY,
  "name",
);
const columnsStoredSettings = useStorage<StoredSettings>(
  MEMBERS_TABLE_STORED_SETTINGS_KEY,
  {
    name: generateSettingsColumn("name"),
    email: generateSettingsColumn("email"),
    office_number: generateSettingsColumn("office_number"),
    company: generateSettingsColumn("company"),
    title: generateSettingsColumn("title"),
    pgroup: generateSettingsColumn("pgroup"),
  },
  localStorage,
  { mergeDefaults: true },
);

const handleColumnSortChanged = (
  params: VxeTableDefines.SortChangeEventParams,
) => {
  const field = params.field as MembersTableField;
  columnsStoredSettings.value[field].sort = params.order || undefined;
  currentTableSortedField.value = field;
};

const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  const field = params.column.field as MembersTableField;
  columnsStoredSettings.value[field].width = params.resizeWidth;
};

const columnsItems = computed(() => {
  return Object.keys(columnsStoredSettings.value).map(
    (column) => columnsStoredSettings.value[column as MembersTableField],
  );
});

const tableColumns = new TableColumns(columnsItems);

const handleCellClick: VxeTableEvents.CellClick = ({ row }) => {
  emit("select", row.uid);
};

const sortTableData = (
  data: MembersTableRow[],
  field?: MembersTableField,
  order?: VxeTablePropTypes.SortOrder,
) => {
  if (!field || !order) return data;

  let list = [...data];

  if (order === "asc" || order === "desc") {
    const lastStringValue =
      order === "asc" ? "\uFFFF\uFFFF\uFFFF\uFFFF" : "\u0000\u0000\u0000\u0000";

    switch (field) {
      case "name":
      case "email":
      case "office_number":
      case "company":
      case "title":
        list = orderBy(
          list,
          [(member) => member[field] ?? lastStringValue],
          [order],
        );
        break;

      case "pgroup":
        list = orderBy(list, [(member) => member.pgroup.name], [order]);
        break;
    }
  }

  return list;
};

const tableSortConfig: VxeTablePropTypes.SortConfig<MembersTableRow> = {
  defaultSort: {
    field: currentTableSortedField.value,
    order:
      columnsStoredSettings.value[currentTableSortedField.value].sort ?? "asc",
  },
  trigger: "cell",
  sortMethod: ({ data, sortList }) => {
    const sortItem = sortList[0];
    const { field, order } = sortItem;

    return sortTableData(data, field as MembersTableField, order);
  },
};

defineExpose({
  sortTableData,
});
</script>
