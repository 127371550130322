<template>
  <DrLayoutContentWithPages
    :class="$style.container"
    :title="currentPGroupTitle"
    :sub-title="currentPGroupSubTitle.text"
    :sub-title-tooltip="currentPGroupSubTitle.tooltip"
    :pages="pages"
    @select-page="handleSelectPage"
  >
    <template #root>
      <DrLoader v-if="isInitialLoading" overlay />
    </template>

    <template #panel>
      <DrNavTree
        :title="$t('permissions.groups')"
        :add-tooltip="$t('permissions.add_group')"
        :tree="groupsList"
        :favorites="groupsListFavorites"
        :current-id="currentPGroupId"
        :is-processing="isLoading"
        :no-data-text="$t('permissions.no_groups_text')"
        :footer="groupListFooter"
        @select="handleSelectGroup"
        @add="handleAddGroup"
        @click-footer="handleShowArchive"
      >
        <template #item-action="{ item, setActive, ref }">
          <DrTooltip
            :content="getGroupCounterTooltip((item as NavTreeItem).counter)"
          >
            <ElDropdown
              trigger="click"
              @command="(cmd) => handleItemMenu(cmd, item.id, ref, setActive)"
              @visible-change="setActive(item.id, $event)"
            >
              <DrIcon name="ellipsis-h" size="sm" />
              <template #dropdown>
                <ElDropdownMenu>
                  <ElDropdownItem
                    v-for="menu_item in groupListItemMenu"
                    :key="menu_item.id"
                    :command="menu_item.id"
                  >
                    {{ menu_item.label }}
                  </ElDropdownItem>
                </ElDropdownMenu>
              </template>
            </ElDropdown>
          </DrTooltip>
        </template>
      </DrNavTree>
    </template>

    <MembersPage
      v-if="['list', 'details'].includes(currentRoute)"
      :group="currentPGroup"
      @select-member="handleSelectMember"
      @invite-members="showInvitePanel"
    />
    <PermissionGroupsStubPage v-else />

    <MembersInvitePanel ref="MembersInvitePanelRef" @invited="handleInvited" />
    <MembersDetailsPanel
      :shown="isMemberDetailsShown"
      :member-id="selectedMemberId"
      @close="handleCloseMemberDetails"
    />

    <CreateRenameCopyDialog
      ref="CreateRenameCopyDialogRef"
      @created="handleNewGroupCreated"
    />
  </DrLayoutContentWithPages>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon, type IconName } from "@shared/ui/dr-icon";
import { DrLayoutContentWithPages } from "@shared/ui/dr-layouts";
import { DrNewLoader as DrLoader } from "@shared/ui/dr-loader";
import {
  DrNavTree,
  type NavItemTab,
  type NavTreeItem,
} from "@shared/ui/dr-nav";
import { DrTooltip } from "@shared/ui/dr-tooltip";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_DATA } from "@setups/index";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import DrStore from "@drVue/store";
import MembersDetailsPanel from "./members/MembersDetailsPanel.vue";
import MembersInvitePanel from "./members/MembersInvitePanel.vue";
import MembersPage from "./members/MembersPage.vue";
import CreateRenameCopyDialog from "./PermissionGroupCreateRenameCopyDialog.vue";
import PermissionGroupsStubPage from "./PermissionGroupsStubPage.vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";

type PermissionsRoute =
  | "list"
  | "details"
  | "files"
  | "tasks"
  | "synergies"
  | "others"
  | "archived";

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

interface Props {
  pgroupId: number | null;
}

const { pgroupId = null } = defineProps<Props>();

const { t } = useI18n();
const isRoomAdmin = ROOM_DATA.userPermissions.administrator;

const $location = useBrowserLocation();
const isInArchive = computed(
  () => $location.value.hash?.startsWith("#/members/archived") ?? false,
);

const currentRoute = ref<PermissionsRoute>("list");
const selectedMemberId = ref<RoomMember["id"]>();
const isMemberDetailsShown = ref(false);

const MembersInvitePanelRef = ref<InstanceType<typeof MembersInvitePanel>>();
const CreateRenameCopyDialogRef =
  ref<InstanceType<typeof CreateRenameCopyDialog>>();

const isLoading = computed(() => !!DrStore.state.room.groups.isLoading);
const isInitialLoading = computed(
  () =>
    DrStore.state.room.groups.isLoading &&
    !DrStore.state.room.groups.isError &&
    !DrStore.state.room.groups.pgroupsList.length,
);

const groupsList = computed(() =>
  DrStore.state.room.groups.pgroupsList
    .filter((group) => !group.builtin_admin_pgroup)
    .map((group) => {
      const all =
        DrStore.getters["room/members/activeDictionaryByGroupId"][group.id]
          ?.length || 0;
      const pending =
        DrStore.getters["room/members/pendingDictionaryByGroupId"][group.id]
          ?.length || 0;

      return {
        id: group.id,
        name: group.name,
        counter: `${pending}/${all}`,
      };
    }),
);

const groupListItemMenu = (() => {
  const menu = [
    {
      id: "copy",
      label: t("shared.copy"),
      shown: isRoomAdmin,
    },
    {
      id: "rename",
      label: t("shared.rename"),
      shown: isRoomAdmin,
    },
    {
      id: "delete",
      label: t("shared.delete"),
      shown: isRoomAdmin,
    },
  ];

  return menu.filter((item) => item.shown);
})();

const groupsListFavorites = computed<NavTreeItem[]>(() => {
  const allMembersCount = DrStore.state.room.members.activeMembersList.length;
  const allInvitesCount = DrStore.state.room.members.invitesList.length;

  const adminGroup = DrStore.state.room.groups.pgroupsList.find(
    (group) => group.builtin_admin_pgroup,
  );
  const adminGroupMembers = adminGroup
    ? DrStore.getters["room/members/activeDictionaryByGroupId"][adminGroup.id]
        ?.length || 0
    : 0;
  const adminGroupInvites = adminGroup
    ? DrStore.getters["room/members/pendingDictionaryByGroupId"][adminGroup.id]
        ?.length || 0
    : 0;

  return [
    {
      id: "root",
      name: t("permissions.all_groups"),
      counter: `${allInvitesCount}/${allMembersCount}`,
    },
    ...(adminGroup
      ? [
          {
            id: adminGroup.id,
            name: adminGroup.name,
            counter: `${adminGroupInvites}/${adminGroupMembers}`,
          },
        ]
      : []),
  ];
});

const currentPGroupId = computed(() => {
  return isInArchive.value ? "archive" : pgroupId || "root";
});

const currentPGroup = computed(() => {
  const pgroupId = currentPGroupId.value;
  if (pgroupId === "archive" || pgroupId === "root") return null;
  return DrStore.state.room.groups.pgroups[pgroupId] ?? null;
});

const currentPGroupTitle = computed(() => {
  if (isInitialLoading.value) {
    return t("shared.loading_data");
  }

  if (isInArchive.value) {
    return t("shared.trash");
  }

  if (currentPGroup.value) {
    return currentPGroup.value.name;
  }
  return t("permissions.all_groups");
});

const currentPGroupSubTitle = computed(() => {
  if (!isInitialLoading.value && currentPGroup.value?.builtin_admin_pgroup) {
    return {
      text: t("permissions.admin_full_access_text"),
      tooltip: t("permissions.admin_full_access_tooltip"),
    };
  }

  return {
    text: "",
    tooltip: "",
  };
});

const pagesConfig = [
  {
    id: "list",
    name: t("shared.users"),
    isAllowed: (_isRoomAdmin: boolean, _group?: Group | null) => true,
  },
  {
    id: "tasks",
    name: t("shared.requests"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && !group?.is_administrator;
    },
  },
  {
    id: "findings",
    name: t("shared.findings"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && group?.is_administrator === false;
    },
  },
  {
    id: "files",
    name: t("shared.data_room"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && !group?.is_administrator;
    },
  },
  {
    id: "synergies",
    name: t("shared.synergies"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && group?.builtin_admin_pgroup === false;
    },
  },
  {
    id: "ai",
    name: t("shared.ai_analysis"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && group?.is_administrator === false;
    },
  },
  {
    id: "visibility",
    name: t("permissions.group_visibility"),
    isAllowed: (isRoomAdmin: boolean, group?: Group | null) => {
      return isRoomAdmin && group?.is_administrator === false;
    },
  },
  {
    id: "admins",
    name: t("permissions.admin_access"),
    isAllowed: (isRoomAdmin: boolean, _group?: Group | null) => {
      return isRoomAdmin;
    },
  },
] as const;

const groupListFooter = computed(() => {
  if (!isRoomAdmin || isInitialLoading.value) return undefined;

  return {
    text: t("shared.trash"),
    icon: "trash" as IconName,
    isActive: isInArchive.value,
  };
});

const pages = computed(() => {
  if (isInArchive.value) return [];

  return pagesConfig.reduce<Readonly<NavItemTab>[]>((acc, item) => {
    if (item.isAllowed(isRoomAdmin, currentPGroup.value)) {
      const isActive =
        currentRoute.value === item.id ||
        (currentRoute.value === "details" && item.id === "list");

      acc.push({
        id: item.id,
        name: item.name,
        isActive,
      });
    }
    return acc;
  }, []);
});

const handleSelectPage = (pageId: string) => {
  $location.value.hash = `#/members/${pageId}${pgroupId ? `?pgroupId=${pgroupId}` : ""}`;
};

const handleSelectGroup = (groupId: string | number) => {
  let targetGroupId = groupId;
  let route = currentRoute.value;

  if (isInArchive.value) {
    handleSelectPage("list");
  }

  if (groupId === "root") {
    targetGroupId = "";
  }

  const targetRoute = pagesConfig.find(
    (item) => item.id === currentRoute.value,
  );
  const targetGroup = targetGroupId
    ? DrStore.state.room.groups.pgroups[targetGroupId as number]
    : null;

  if (!targetRoute?.isAllowed(isRoomAdmin, targetGroup)) route = "list";

  $location.value.hash = `#/members/${route}${targetGroupId ? `?pgroupId=${targetGroupId}` : ""}`;
};

const handleAddGroup = (reference: HTMLElement, params?: OpenPopupParams) => {
  CreateRenameCopyDialogRef.value?.show(
    reference,
    {
      type: "create",
    },
    params,
  );
};

const handleRenameCopyGroup = (
  reference: HTMLElement,
  group: Group,
  type: "rename" | "copy",
  params: OpenPopupParams,
) => {
  CreateRenameCopyDialogRef.value?.show(
    reference,
    {
      type,
      group,
    },
    params,
  );
};

const handleDeleteGroup = (group: Group) => {
  let body = t("permissions.group_delete_confirm_text", {
    group: group.name,
  });
  const title = t("permissions.group_delete_confirm_title");

  const membersCount = DrStore.getters["room/members/activeByGroupId"](
    group.id,
  ).length;
  if (membersCount) {
    body = `${body} ${t("permissions.group_delete_confirm_text_with_users", { count: membersCount })}`;
  }

  ElMessageBox.confirm(body, title, {
    customClass: "el-message-box--warning",
    confirmButtonText: t("shared.delete"),
  })
    .then(() => {
      DrStore.dispatch("room/groups/delete", group.id).then(
        () => {
          $notifySuccess(t("permissions.group_deleted", { name: group.name }));
        },
        () => {
          $notifyDanger(t("permissions.failed_to_delete_group"));
        },
      );

      if (pgroupId === group.id) {
        handleSelectGroup("root");
      }
      DrStore.dispatch("room/groups/load");
    })
    .catch(() => {});
};

const handleInvited = () => {
  DrStore.dispatch("room/members/load");
};

const handleShowArchive = () => {
  if (!isInArchive.value) {
    handleSelectGroup("root");
    handleSelectPage("archived");
  }
};

const getGroupCounterTooltip = (counter?: string): string => {
  if (!counter) return "";
  const [pending, all] = counter.split("/").map(parseFloat);
  return t("permissions.group_counter_tooltip", { pending, all });
};

const handleItemMenu = (
  cmd: string,
  groupId: NavTreeItem["id"],
  ref: HTMLElement,
  setTreeItemActive: (id: NavTreeItem["id"], active: boolean) => void,
) => {
  const group = DrStore.state.room.groups.pgroups[groupId];
  if (!group) {
    $notifyDanger(t("permissions.get_group_unexpected_error"));
    return;
  }

  switch (cmd) {
    case "rename":
    case "copy": {
      handleRenameCopyGroup(ref, group, cmd, {
        openedCallback: () => setTreeItemActive(groupId, true),
        closedCallback: () => setTreeItemActive(groupId, false),
      });
      break;
    }
    case "delete": {
      handleDeleteGroup(group);
      break;
    }
  }
};

const handleNewGroupCreated = (group: Group) => {
  handleSelectGroup(group.id);
  handleSelectPage("tasks");
};

const handleSelectMember = (uid: RoomMember["uid"]) => {
  const member = DrStore.state.room.members.membersByUid[uid];
  if (!member) {
    return;
  }
  $location.value.hash = `#/members/${member.id}${pgroupId ? `?pgroupId=${pgroupId}` : ""}`;
};

const handleCloseMemberDetails = () => {
  isMemberDetailsShown.value = false;
  handleSelectGroup(currentPGroup.value?.id || "root");
};

const showInvitePanel = () => {
  MembersInvitePanelRef.value?.open({
    groupId: pgroupId,
  });
};

// check access to the permission group
watch(
  (): [boolean | null, number] => [
    isLoading.value,
    DrStore.state.room.groups.pgroupsList.length,
  ],
  ([stillLoading, loadedPGroupsCount]) => {
    if (stillLoading || !loadedPGroupsCount) return;

    if (pgroupId && !currentPGroup.value) {
      $location.value.hash = "#/members/list";
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => $location.value.hash,
  (value) => {
    if (!value) return;

    const route = value.replace("#/members/", "").split("?")[0] as
      | PermissionsRoute
      | `${number}`;

    if (route.match(/^(\d+)$/i)) {
      const memberId = parseInt(route, 10);
      // check member exists
      if (DrStore.state.room.members.members[memberId]) {
        currentRoute.value = "details";
        selectedMemberId.value = memberId;
        isMemberDetailsShown.value = true;
      } else {
        $location.value.hash = "#/members/list";
      }
    } else {
      currentRoute.value = route as PermissionsRoute;
    }
  },
  {
    immediate: true,
  },
);
</script>

<style module lang="scss">
@use "@app/styles/scss/values";

.container {
  height: calc(100vh - #{values.$header-height});
}
</style>
