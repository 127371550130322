<template>
  <TablePageLayout
    :title="$t('permissions.page_title_users')"
    :search="searchPhrase"
    :action="toolbarAction"
    @search="handleSearch"
    @action="handleAction"
    @action-menu="handleActionMenu"
  >
    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="users"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="searchPhrase = ''"
        >
          Clear search query
        </ElButton>
      </template>
    </DrOverlayEmpty>

    <MembersTable
      :items="filteredMembers"
      @select="$emit('select-member', $event)"
    />
  </TablePageLayout>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";

import { $notifyDanger, isStringContains } from "@drVue/common";
import DrStore from "@drVue/store";
import TablePageLayout from "../TablePageLayout.vue";
import MembersTable from "./table/MembersTable.vue";

import type { Group, MembersTableRow } from "./types";

interface Props {
  group: Group | null;
}

interface Emits {
  (event: "select-member", id: MembersTableRow["uid"]): void;
  (event: "invite-members"): void;
}

const { group } = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();

const searchPhrase = ref("");
const toolbarAction = {
  label: t("permissions.invite_members.invite_user"),
  menu: [
    {
      id: "export",
      label: t("permissions.export_contects"),
    },
  ],
};

const isDataLoading = computed(() => DrStore.state.room.members.isLoading);
const membersList = computed(
  () => DrStore.state.room.members.activeMembersList,
);

const filteredMembers = computed(() => {
  if (searchPhrase.value || group) {
    return membersList.value.filter((member) => {
      if (group && group.id !== member.pgroup?.id) {
        return false;
      }
      if (
        searchPhrase.value &&
        !isStringContains(searchPhrase.value, member?.name || member.initial)
      ) {
        return false;
      }
      return true;
    });
  }

  return membersList.value;
});

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
  };

  if (isDataLoading.value) {
    return data;
  }

  if (!filteredMembers.value.length) {
    data.active = true;
    data.title = t("permissions.no_users_in_group_title");
    data.subtitle = t("permissions.no_users_in_group_subtitle");

    if (searchPhrase.value) {
      data.btnClearSearch = true;
      data.title = t("shared.no_results_for", { query: searchPhrase.value });
      data.subtitle = "";
    }
  }

  return data;
});

const handleSearch = (phrase: string) => {
  searchPhrase.value = phrase.trim();
};

const handleAction = () => {
  emit("invite-members");
};

const handleActionMenu = (_id: string) => {
  /** @todo implement */
  $notifyDanger(t("shared.not_implemented_functionality"));
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
</style>
