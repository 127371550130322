import { keyBy } from "lodash-es";
import { orderBy } from "lodash-es";
import { h } from "vue";

import { CustomViewObjectTypes } from "@setups/types";
import { t } from "@app/vue/i18n";
import BookmarkCell from "@drVue/components/room/documents/cells/BookmarkCell.vue";
import MenuCell from "@drVue/components/room/documents/cells/MenuCell.vue";
import RelatedRequestsCell from "@drVue/components/room/documents/cells/RelatedRequestsCell.vue";
import TitleCell from "@drVue/components/room/documents/cells/TitleCell.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import { formatFieldValue } from "@drVue/store/modules/client-dashboard/fields/utils";
import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";
import SizeCell from "./cells/SizeCell.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { TaskId } from "@drVue/store/pinia/room/tasks";
import type { RootState } from "@drVue/store/state";
import type { Store } from "vuex";

// These columns are used in two table: Documents and Archive (Trash) tables.
export default class TableColumns {
  constructor(
    private $store: Store<RootState>,
    private removedColumns?: string[], // allows to remove column by it's field
  ) {}

  tasksService: any;
  categoriesService: any;

  get columnsConfig() {
    const view = this.$store.getters["common/customViews/defaultView"](
      CustomViewObjectTypes.DataRoom,
    );

    const columns = view.settings.columns;
    return columns ? keyBy(columns, "field") : {};
  }

  get customFields(): FieldItem[] {
    return this.$store.getters["clientDashboard/customFields/byObjectType"](
      CustomViewObjectTypes.DataRoom,
    );
  }

  get columns(): DrVxeTableColumn<DocsItem>[] {
    let cols: DrVxeTableColumn<DocsItem>[] = [
      this.checkboxColumn(),
      this.bookmarkColumn(),
      this.indexColumn(),
      this.titleColumn(),
      ...this.customFields.map((field) => this.customFieldColumn(field)),
      this.sizeColumn(),
      this.dateColumn(),
      this.requestsColumn(),
      this.menuColumn(),
    ];

    if (this.removedColumns) {
      cols = cols.filter((col) => !this.removedColumns!.includes(col.field));
    }

    // make all columns sortable and restore saved config
    cols.forEach((col) => {
      const columnConfig = this.columnsConfig[col.field];

      if (col.resizable !== false) {
        col.width = columnConfig?.width || col.width;
      }
      col.visible = !columnConfig?.hidden;
      col.sortable = col.sortable ?? true;
    });

    const orderedCols = orderBy(
      cols,
      [
        "fixed", // keep fixed element always first
        (col) => {
          // Keep this particular order for the most important columns.
          if (col.field === "_checkbox") return -4;
          if (col.field === "_bookmark") return -3;
          if (col.field === "tree_index") return -2;
          if (col.field === "name") return -1;

          return this.columnsConfig[col.field]?.order;
        },
      ],
      ["asc", "asc"],
    );

    return orderedCols;
  }

  private checkboxColumn(): DrVxeTableColumn<DocsItem> {
    return {
      field: "_checkbox",
      type: "checkbox",
      title: "",
      fixed: "left",
      resizable: false,
      width: 35,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      sortable: false,
    };
  }

  private bookmarkColumn(): DrVxeTableColumn<DocsItem> {
    return {
      field: "_bookmark",
      title: "",
      fixed: "left",
      width: 30,
      resizable: false,
      className: "dr-vxe-column--centered",
      slots: {
        default: (params) => [
          h(BookmarkCell, {
            isBookmarked: !!params.row.bookmarked,
          }),
        ],
      },
    };
  }

  private indexColumn(): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: "tree_index",
      title: t("data_room.fields.index"),
      fixed: "left",
      minWidth: 100,
      width: 100,
      sortBy: ({ row }) => row.treePosition,
    };
  }

  private titleColumn(): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: "name",
      title: t("data_room.fields.name"),
      fixed: "left",
      minWidth: 300,
      resizable: true,
      // Go to TitleCell and look at the div with v-html="textSnippet". The div
      // is used for showing the search results. If showOverflow is true then
      // vxe-table shrinks the div. If false then it shows it. Looks like the
      // setting works inversely.
      showOverflow: false,
      slots: {
        default: ({ row }) => [
          h(TitleCell, {
            row: row,
          }),
        ],
      },
    };
  }

  private sizeColumn(): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: "size",
      title: t("data_room.fields.size"),
      minWidth: 80,
      width: 130,
      sortBy: ({ row }) => {
        if (row.type === DOC_ITEM_TYPES.Document) return row.size;
        if (row.type === DOC_ITEM_TYPES.Folder) return row.aggregates.files;

        return 0;
      },
      slots: {
        default: ({ row }) => [
          h(SizeCell, {
            row: row,
          }),
        ],
      },
    };
  }

  private dateColumn(): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: "date_updated",
      title: t("data_room.fields.date_updated"),
      resizable: false,
      width: 130,
      sortBy: ({ row }) => row.date_updated?.getTime(),
      slots: {
        default: ({ row }) => {
          const anyrow = row as any;
          const text = drUserTime(anyrow.date_updated, "full-date") as string;
          return [text];
        },
      },
    };
  }

  private requestsColumn(): DrVxeTableColumn<DocsItem> {
    return {
      field: "_requests",
      title: t("data_room.fields.requests.title"),
      width: 70,
      resizable: true,
      className: "dr-vxe-column--centered",
      sortBy: ({ row }: { row: DocsItem }) => {
        let relatedTasks: TaskId[] | null = null;

        const getFolderTasks = "room/tasksRelated/getFolderTasks";
        const getDocumentTasks = "room/tasksRelated/getDocumentTasks";

        if (row.type === DOC_ITEM_TYPES.Folder) {
          relatedTasks = this.$store.getters[getFolderTasks](row.id) || [];
        }

        if (row.type === DOC_ITEM_TYPES.Document) {
          relatedTasks = this.$store.getters[getDocumentTasks](row.id) || [];
        }
        return relatedTasks?.length || 0;
      },
      slots: {
        default: ({ row }) => [
          h(RelatedRequestsCell, {
            row: row,
            tasksService: this.tasksService,
            categoriesService: this.categoriesService,
          }),
        ],
      },
    };
  }

  private menuColumn(): DrVxeTableColumn<DocsItem> {
    return {
      field: "_menu",
      title: "",
      minWidth: 60,
      resizable: false,
      slots: {
        default: (params) => [
          h(MenuCell, {
            table: params.$table,
            row: params.row,
          }),
        ],
      },
    };
  }

  private customFieldColumn(field: FieldItem): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: field.key,
      title: field.label,
      width: 140,
      resizable: true,
      sortBy: ({ row }) => getSortValue(field, row.custom_data[field.key]),
      slots: {
        default: ({ row }) => {
          return [
            h(
              "span",
              {},
              formatFieldValue(
                field,
                row.custom_data ? row.custom_data[field.key] : "-",
              ) || "",
            ),
          ];
        },
      },
    };
  }
}
