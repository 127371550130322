<template>
  <CreateRequestPanel ref="createRequestPanelRef" />
  <ElDropdown
    trigger="click"
    @command="(command) => handleMenuActions(command)"
  >
    <ElLink ref="linkBtnRef" type="primary" :underline="false">
      {{ $t("file_viewer.sidebar.related_requests.button") }}
      <ElIcon class="el-icon--right">
        <i class="fas fa-plus-circle" />
      </ElIcon>
    </ElLink>
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem command="attachToTask">
          {{ $t("file_viewer.sidebar.related_requests.button_link_to_existing") }}
        </ElDropdownItem>
        <ElDropdownItem v-if="canCreateTasks" command="createNewTask">
          {{ $t("file_viewer.sidebar.related_requests.button_link_to_new") }}
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>

  <FolderMenuPlacer />
</template>

<script setup lang="ts">
import { markRaw, ref } from "vue";

import { ROOM_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import FolderMenuAttachToRequest from "@drVue/components/room/documents/components/FolderMenuAttachToRequest.vue";
import FolderMenuPlacer from "@drVue/components/room/documents/components/FolderMenuPlacer.vue";
import { useFolderMenuBus } from "@drVue/components/room/documents/use/useFolderMenuBus";
import CreateRequestPanel from "@drVue/components/room/tasks/TaskOverviewPage/CreateRequestPanel.vue";

const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;

const props = defineProps<{ documentId: number }>();

const folderMenuBus = useFolderMenuBus();
const documentsStore = useDocumentsStore();

const linkBtnRef = ref<HTMLElement | null>(null);
const createRequestPanelRef = ref<InstanceType<
  typeof CreateRequestPanel
> | null>(null);

function createNewTask() {
  createRequestPanelRef.value?.open({
    attachDocumentIds: [props.documentId],
  });
}

function attachToTask() {
  const document = documentsStore.fileByIdMap[props.documentId];

  if (!document || !linkBtnRef.value) return;

  folderMenuBus.emit("menu:show", {
    reference: linkBtnRef.value,
    params: {
      folders: [],
      documents: [document],
    },
    menuComponent: markRaw(FolderMenuAttachToRequest),
    placement: "left",
  });
}

function handleMenuActions(command: string) {
  if (command === "createNewTask") {
    createNewTask();
  } else if (command === "attachToTask") {
    attachToTask();
  }
}
</script>
