<template>
  <ElSelect
    ref="selectRef"
    clearable
    persistent
    style="width: 100%"
    :model-value="editProps.value"
    :disabled="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    :filterable="!!editProps.schema.extra.filterable"
    :teleported="!editProps.schema.extra.noTeleported"
    v-bind="editProps.veeField"
  >
    <template v-if="hasValidGoupedOptions">
      <ElOptionGroup
        v-for="group in editProps.schema.extra.select_options_grouped"
        :key="group.label"
        :label="group.label"
      >
        <ElOption
          v-for="opt in group.options"
          :key="opt.value ?? opt.label"
          :label="opt.label"
          :value="opt.value ?? opt.label"
          :disabled="opt.isDisabled"
        />
      </ElOptionGroup>
    </template>

    <template v-else-if="hasValidOptions">
      <ElOption
        v-for="opt in editProps.schema.extra.select_options"
        :key="opt.value ?? opt.label"
        :label="opt.label"
        :value="opt.value ?? opt.label"
        :disabled="opt.isDisabled"
      />
    </template>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { computed, ref } from "vue";

import type { FieldProps } from "../types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);

const hasValidOptions = computed(() => {
  const extra = props.editProps.schema.extra;
  return !!(extra && "select_options" in extra);
});

const hasValidGoupedOptions = computed(() => {
  const extra = props.editProps.schema.extra;
  return !!(extra && "select_options_grouped" in extra);
});
</script>
