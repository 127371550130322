import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import { CLIENT_DASHBOARD_DATA, ORG_MEMBER_DATA } from "@setups/data";
import { trackVueRouterChange } from "@app/insight";
import NewReportsPromo from "@drVue/components/client-dashboard/activity/NewReportsPromo.vue";
import { isBillingVisible } from "@drVue/components/client-dashboard/billing/utils";
import LookerMain from "@drVue/components/client-dashboard/looker/LookerMain.vue";
import { isLookerAccessible } from "@drVue/components/client-dashboard/looker/utils";
import SettingsDealsTrash from "@drVue/components/client-dashboard/settings/DealsArchive/SettingsDealsTrash.vue";
import { initSentry } from "@drVue/components/initSentry";
import DrStore from "@drVue/store";
import Billing from "./billing";
import App from "./ClientDashboardApp.vue";
import Deals from "./deals";
import Notifications from "./notifications";
import Settings from "./settings";
import SettingsAuth from "./settings/Auth";
import SettingsGeneral from "./settings/General";
import SettingsMetadata from "./settings/Metadata";
import SettingsPipelineAccess from "./settings/PipelineAccess";
import UnpaidApp from "./unpaid-app.vue";
import Users from "./users";
import {
  YourWork,
  YourWorkDealTasks,
  YourWorkFindings,
  YourWorkSynergies,
  YourWorkTasks,
} from "./your-work";

import type { RouteRecordRaw } from "vue-router";

function createClientDashboardRoutes(): RouteRecordRaw[] {
  return [
    { path: "", redirect: "/deals" },
    {
      path: "/deals/:viewSlug?/:dealUid?",
      component: Deals,
    },
    { path: "/members", redirect: "/people" },
    { path: "/users", redirect: "/people" },
    { path: "/people", component: Users },
    {
      path: "/settings",
      component: Settings,
      meta: {
        permissions:
          ORG_MEMBER_DATA.group.is_administrator || isBillingVisible(),
      },
      children: [
        // TODO @dgonchar: fix the redirect value. It must be relative.
        {
          path: "",
          redirect: ORG_MEMBER_DATA.group.is_administrator
            ? "/settings/general"
            : "/settings/billing",
        },
        { path: "general", component: SettingsGeneral },
        { path: "metadata", component: SettingsMetadata },
        {
          path: "auth",
          component: SettingsAuth,
          meta: {
            permissions: ORG_MEMBER_DATA.group.is_administrator,
          },
        },
        {
          path: "members",
          component: SettingsPipelineAccess,
          meta: {
            permissions: ORG_MEMBER_DATA.group.is_administrator,
          },
        },
        {
          path: "billing",
          component: Billing,
          meta: {
            permissions: isBillingVisible(),
          },
        },
        {
          path: "deals-trash",
          component: SettingsDealsTrash,
          meta: {
            permissions: ORG_MEMBER_DATA.group.is_administrator,
          },
        },
      ],
    },
    {
      path: "/notifications",
      component: Notifications,
    },
    {
      path: "/reports",
      component: isLookerAccessible() ? LookerMain : NewReportsPromo,
    },

    {
      path: "/your-work/",
      component: YourWork,
      redirect: "/your-work/tasks",
      children: [
        {
          path: "tasks",
          component: YourWorkTasks,
        },
        {
          path: "findings",
          component: YourWorkFindings,
          meta: {
            permissions:
              ORG_MEMBER_DATA?.client?.enable_dashboard &&
              ORG_MEMBER_DATA?.client?.enable_findings,
          },
        },
        {
          path: "deal-tasks",
          component: YourWorkDealTasks,
          meta: {
            permissions:
              ORG_MEMBER_DATA?.client?.enable_dashboard &&
              ORG_MEMBER_DATA?.client?.enable_deal_tasks,
          },
        },
        { path: "synergies", component: YourWorkSynergies },
      ],
    },

    // temporary add redirects to reports
    { path: "/activity", redirect: "/reports" },
    { path: "/activity/dashboard", redirect: "/reports/dashboard" },
    { path: "/activity/history", redirect: "/reports/history" },
    { path: "/activity/phases", redirect: "/reports/phases" },
    //fallback to deals list
    { path: "/:pathMatch(.*)*", redirect: "/deals" },
  ];
}
function initializeStore() {
  DrStore.dispatch("clientDashboard/deals/load");
  DrStore.dispatch("clientDashboard/orgUsers/load");

  if (ORG_MEMBER_DATA.client.room_phases) {
    DrStore.commit(`clientDashboard/phases/setItems`, {
      items: ORG_MEMBER_DATA.client.room_phases,
    });
  }

  if (ORG_MEMBER_DATA.client.custom_fields) {
    DrStore.commit(`clientDashboard/customFields/setItems`, {
      items: ORG_MEMBER_DATA.client.custom_fields,
    });
  }

  DrStore.commit(`clientDashboard/settings/update`, {
    data: ORG_MEMBER_DATA.client,
  });

  DrStore.commit("common/customViews/set", CLIENT_DASHBOARD_DATA.custom_views);
}

export function createClientDashboardApp() {
  initializeStore();

  const app = createApp(CLIENT_DASHBOARD_DATA.is_paid ? App : UnpaidApp);
  const router = createRouter({
    history: createWebHashHistory(),
    routes: createClientDashboardRoutes(),
  });

  initSentry(app, router);

  app.use(router);
  trackVueRouterChange(router);

  //router.beforeEach(beforeEachHook);

  // new Idle({
  //   awayTimeout: 30 * 1000,
  // }).start();
  //
  // Idle.onAwayBack.subscribe(() => {
  //   if (!isOnline()) {
  //     return;
  //   }
  //   DrStore.dispatch("clientDashboard/deals/load");
  // });
  // setupOfflineAlert();

  return app;
}
