import { difference, keyBy } from "lodash-es";
import { h, unref } from "vue";
import DrVxeCheckboxCell from "@shared/ui/dr-vxe-grid/DrVxeCheckboxCell.vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { TaskFieldAccessType } from "@setups/enums";
import {
  insightTrack,
  OrgSettingsCustomFieldsTrackingEvent,
} from "@app/insight";
import { isCategory, isTask } from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import { ORG_MEMBER_DATA, ROOM_DATA } from "@app/setups";
import { t } from "@app/vue/i18n";
import { serializeCustomData } from "@drVue/api-service/parse";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { drUserTime } from "@drVue/filters/drUserTime";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { MENU_COLUMN_FIELD, TableColumnsBase } from "@drVue/TableColumnsBase";
import { AssigneesEdit, AssigneesView } from "./cells/assignees";
import CategoryKeyCell from "./cells/CategoryKeyCell.vue";
import { CommentsEdit, CommentsView } from "./cells/comments";
import { ContextMenuEdit, ContextMenuView } from "./cells/context-menu";
import { CreatorView } from "./cells/creator";
import DragCell from "./cells/DragCell.vue";
import { DueDateEdit, DueDateView } from "./cells/due-date";
import FilesCell from "./cells/FilesCell.vue";
import FindingsCell from "./cells/FindingsCell.vue";
import { LabelsEdit, LabelsView } from "./cells/labels";
import { PriorityEdit, PriorityView } from "./cells/priority";
import { ReviewersEdit, ReviewersView } from "./cells/reviewers";
import { StartDateEdit, StartDateView } from "./cells/start-date";
import { StatusEdit, StatusView } from "./cells/status";
import ValueDriversCell from "./cells/ValueDriversCell.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { RequestsItem } from "@drVue/components/room/tasks/TasksTable/types";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Task, TaskReviewer } from "@drVue/store/pinia/room/tasks";
import type { CustomViewColumn } from "@setups/types";
import type { CheckboxParams } from "@shared/ui/dr-vxe-grid/types";
import type { MaybeRef, Ref } from "vue";

interface Permissions {
  canManageTasks: boolean;
  isFindingsAccessible: boolean;
  taskCustomFieldsAccess?: TaskFieldAccessType;
  taskStartDueDatesAccess?: TaskFieldAccessType;
}

export default class TableColumns extends TableColumnsBase<RequestsItem> {
  constructor(
    private readonly viewColumns: Ref<CustomViewColumn[]>,
    private readonly customFields: Ref<FieldItem[]>,
    private readonly permissions: Permissions,
    private readonly inTheArchive: MaybeRef<boolean>,
  ) {
    super();
  }

  public tasksStore: ReturnType<typeof useTasksStore> = useTasksStore(pinia);

  public findingsStore = useFindingsStore(pinia);
  public valueDriversStore = useValueDriversStore(pinia);

  private get allowShowCustomFields() {
    return [TaskFieldAccessType.View, TaskFieldAccessType.Edit].includes(
      this.permissions.taskCustomFieldsAccess || TaskFieldAccessType.NoAccess,
    );
  }

  private get allowEditCustomFields() {
    return this.permissions.taskCustomFieldsAccess === TaskFieldAccessType.Edit;
  }

  private get allowShowStartDueDatesFields() {
    return [TaskFieldAccessType.View, TaskFieldAccessType.Edit].includes(
      this.permissions.taskStartDueDatesAccess || TaskFieldAccessType.NoAccess,
    );
  }

  private get allowEditStartDueDatesFields() {
    return (
      this.permissions.taskStartDueDatesAccess === TaskFieldAccessType.Edit
    );
  }

  inlineEditor = new TableInlineEditor((changes: any, task: Task) => {
    const isChangingCustomData = "custom_data" in changes;
    if (isChangingCustomData) {
      serializeCustomData(changes.custom_data, this.customFields.value);

      const customFieldKey = Object.keys(changes.custom_data)[0];
      const customFieldInfo = this.customFields.value.find(
        (field) => field.key === customFieldKey,
      );

      if (customFieldInfo) {
        insightTrack(OrgSettingsCustomFieldsTrackingEvent.FieldValueChanged, {
          source: "table",
          object_type: customFieldInfo.object_type,
          type: customFieldInfo.field_type,
          name: customFieldInfo.label,
        });
      }
    }

    if ("labels" in changes && changes.labels) {
      const existLabels = task.labels;
      const addIds = difference(changes.labels, existLabels);
      const removeIds = difference(existLabels, changes.labels);

      return this.tasksStore.updateLabels(
        task.id,
        addIds.map((id) => ({ label_key_id: id })),
        removeIds.map((id) => ({ label_key_id: id })),
      );
    }

    return this.tasksStore.patchTaskV2(task.uid, changes);
  }, "id");

  getUserColumns() {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): DrVxeTableColumn<RequestsItem>[] {
    const customFields = this.allowShowCustomFields
      ? this.customFields.value.map((field) =>
          this.customFieldColumn(
            field,
            !this.allowEditCustomFields || unref(this.inTheArchive),
            true,
          ),
        )
      : [];

    return [
      this.dragColumn(),
      this.checkboxColumn(),
      this.keyColumn(),
      this.titleColumn(),
      this.priorityColumn(),
      this.statusColumn(),
      this.assigneesColumn(),
      this.reviewersColumn(),
      this.creatorColumn(),
      this.findingsColumn(),
      this.commentsColumn(),
      this.filesColumn(),
      this.labelsColumn(),
      this.startDateColum(),
      this.dueDateColumn(),
      this.updatedColum(),
      this.contextMenuColumn(),
      this.synergiesValueDriverColumn(),
      ...customFields,
    ];
  }

  private dragColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      field: "_drag",
      title: "",
      fixed: "left",
      resizable: false,
      width: 25,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      slots: {
        default: ({ row, $table }) => {
          const isSorting = $table.getSortColumns().length > 0;

          const isDisabled = isSorting || tasksFilterService.isActive();
          return h(DragCell, { isHidden: isCategory(row), isDisabled });
        },
      },
    };
  }

  private checkboxColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      field: "_checkbox",
      type: "checkbox",
      title: "",
      fixed: "left",
      resizable: false,
      width: 35,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      sortable: false,
      slots: {
        checkbox: (params) => {
          const { row } = params;
          const checkboxParams = params as unknown as CheckboxParams;

          return [
            isCategory(row)
              ? h(
                  CategoryKeyCell,
                  {
                    category: row,
                  },
                  {
                    checkbox: () =>
                      h(DrVxeCheckboxCell, { params: checkboxParams }),
                  },
                )
              : h(DrVxeCheckboxCell, { params: checkboxParams }),
          ];
        },
      },
    };
  }

  private keyColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      sortable: true,
      field: "key",
      title: "ID",
      minWidth: 45,
      width: 48,
    };
  }

  private titleColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      sortable: true,
      width: 500,
      field: "title",
      title: t("shared.title"),
    };
  }

  private commentsColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      className: "dr-vxe-column--no-ellipsis",
      field: "comments_count",
      title: t("shared.reply"),
      width: 72,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "comments_count",
              editComponent: CommentsEdit,
              viewComponent: CommentsView,
              extra: {
                hasManagerAccess: this.permissions.canManageTasks,
              },
              isReadOnly: unref(this.inTheArchive),
            },
            params,
          );
        },
      },
    };
  }

  private filesColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      className: "dr-vxe-column--no-ellipsis",
      field: "documents",
      title: t("shared.files"),
      width: 72,
      sortable: true,
      slots: {
        default: ({ $table, row }) =>
          h(FilesCell, {
            table: $table,
            taskId: row.id,
            allowAttachNewFiles: this.permissions.canManageTasks,
            isReadOnly: unref(this.inTheArchive),
          }),
      },
    };
  }

  private priorityColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.permissions.canManageTasks,
      field: "priority",
      title: t("shared.priority"),
      width: 75,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "priority",
              optimistic: true,
              editComponent: PriorityEdit,
              viewComponent: PriorityView,
              isReadOnly:
                !this.permissions.canManageTasks || unref(this.inTheArchive),
            },
            params,
          );
        },
      },
    };
  }

  private statusColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      field: "status_id",
      title: t("shared.status"),
      width: 156,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "status_id",
              optimistic: true,
              editComponent: StatusEdit,
              viewComponent: StatusView,
              isReadOnly:
                !this.permissions.canManageTasks || unref(this.inTheArchive),
            },
            params,
          );
        },
      },
    };
  }

  private assigneesColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.permissions.canManageTasks,
      showOverflow: "ellipsis",
      field: "assignees",
      title: t("shared.assignees"),
      width: 156,
      sortable: false,
      slots: {
        default: (params) => {
          if (isCategory(params.row)) return "";

          if (isTask(params.row)) {
            return this.inlineEditor.renderInlineField(
              {
                type: FieldSchemaType.Custom,
                prop: "assignees",
                extra: {
                  actionLabel: t("requests.assign_to_me"),
                  categoryId: params.row.category_id,
                },
                viewComponent: AssigneesView,
                editComponent: AssigneesEdit,
                isReadOnly: unref(this.inTheArchive),
              },
              params,
            );
          }

          return "";
        },
      },
    };
  }

  private reviewersColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.permissions.canManageTasks,
      showOverflow: "ellipsis",
      field: "reviewers",
      title: t("shared.reviewers"),
      width: 156,
      sortable: false,
      slots: {
        default: (params) => {
          if (isCategory(params.row)) return "";

          if (isTask(params.row) && params.row.reviewers) {
            return this.inlineEditor.renderInlineField(
              {
                type: FieldSchemaType.Custom,
                prop: "reviewers",
                extra: {
                  actionLabel: t("requests.need_my_review"),
                  isReviewComplete: params.row.reviewers.every(
                    (u: TaskReviewer) => u.mark_complete,
                  ),
                  categoryId: params.row.category_id,
                },
                viewComponent: ReviewersView,
                editComponent: ReviewersEdit,
                isReadOnly: unref(this.inTheArchive),
              },
              params,
            );
          }

          return "";
        },
      },
    };
  }

  private creatorColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      sortable: false,
      width: 156,
      field: "sender_id",
      title: t("shared.creator"),
      slots: {
        default: (params) => {
          if (isTask(params.row)) {
            return h(CreatorView, {
              modelValue: params.row.sender_id,
            });
          }

          return "";
        },
      },
    };
  }

  private findingsColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.permissions.isFindingsAccessible,
      field: "findings",
      title: t("shared.findings"),
      width: 74,
      sortable: false,
      slots: {
        default: ({ row }) =>
          h(FindingsCell, {
            findings: this.findingsStore.listByTaskUid[row.uid] || [],
          }),
      },
    };
  }

  private dueDateColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.allowShowStartDueDatesFields,
      field: "due_date",
      title: t("shared.due_date"),
      width: 92,
      sortable: true,
      slots: {
        default: (params) => {
          if (isCategory(params.row)) return "";

          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "due_date",
              optimistic: true,
              viewComponent: DueDateView,
              editComponent: DueDateEdit,
              isReadOnly:
                unref(this.inTheArchive) ||
                ((params.row as Task).is_due_date_dynamic
                  ? !(
                      ORG_MEMBER_DATA.group?.is_administrator &&
                      this.allowEditStartDueDatesFields
                    )
                  : !this.allowEditStartDueDatesFields),
            },
            params,
          );
        },
      },
    };
  }

  private labelsColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !this.permissions.canManageTasks,
      field: "labels",
      title: t("shared.labels"),
      width: 96,
      sortable: false,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "labels",
              placeholder: "Search for a label...",
              editComponent: LabelsEdit,
              viewComponent: LabelsView,
              isReadOnly: unref(this.inTheArchive),
            },
            params,
          );
        },
      },
    };
  }

  private startDateColum(): DrVxeTableColumn<RequestsItem> {
    return {
      visible: false,
      isInaccessible: !this.allowShowStartDueDatesFields,
      field: "start_date",
      title: t("shared.start_date"),
      width: 92,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "start_date",
              optimistic: true,
              viewComponent: StartDateView,
              editComponent: StartDateEdit,
              isReadOnly:
                unref(this.inTheArchive) ||
                ((params.row as Task).is_start_date_dynamic
                  ? !(
                      ORG_MEMBER_DATA.group?.is_administrator &&
                      this.allowEditStartDueDatesFields
                    )
                  : !this.allowEditStartDueDatesFields),
            },
            params,
          );
        },
      },
    };
  }

  private updatedColum(): DrVxeTableColumn<RequestsItem> {
    return {
      visible: false,
      isInaccessible: !this.permissions.canManageTasks,
      field: "last_updated",
      title: t("shared.updated"),
      width: 72,
      sortable: true,
      slots: {
        default: ({ row }) => {
          if (isCategory(row)) return "";

          return drUserTime(row.last_updated, "short-date") ?? "";
        },
      },
    };
  }

  private contextMenuColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      field: MENU_COLUMN_FIELD,
      width: 35,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              type: FieldSchemaType.Custom,
              prop: "_menu",
              label: "_menu",
              viewComponent: ContextMenuView,
              editComponent: ContextMenuEdit,
              extra: {
                inTheArchive: unref(this.inTheArchive),
              },
            },
            params,
          );
        },
      },
    };
  }

  private synergiesValueDriverColumn(): DrVxeTableColumn<RequestsItem> {
    return {
      isInaccessible: !ROOM_DATA.enableClientSynergies,
      sortable: false,
      field: "value_drivers",
      title: t("shared.value_drivers"),
      width: 156,
      slots: {
        default: ({ row }) =>
          h(ValueDriversCell, {
            values: this.valueDriversStore.dictByTaskId[row.uid] || [],
          }),
      },
    };
  }
}
